import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/footer-logo.png";
import qrimg from "../assets/images/qrcode.png";

const Footer = () => {
  const navigate = useNavigate();
  const handleScroll = () => {
    navigate("/");
    window.scrollTo(0, 1400);
  };
  return (
    <footer>
      <div className="Footer-Center">
        <div className="FooterInfo">
          <Link to="/">
            <img src={logo} alt="footer-logo" />
          </Link>
          <p style={{ color: "#fff", textAlign: "justify" }}>
            PG Dekho is an online platform for PGs accommodations , providing ,
            options for working Professionals and students from room to flats
            across India.
            {/* PG Dekho is India’s trusted platform for simplifying Paying Guest
            (PG) accommodation. Established in 2023, our mission is to
            seamlessly connect tenants with property owners, ensuring a smooth
            and efficient experience for both. Whether you are a student,
            working professional, or property owner, PG Dekho provides a
            streamlined solution for all your accommodation needs. Since its
            inception, PG Dekho has served more than 10,000 satisfied customers
            across India. Focusing on trust, transparency, and convenience, we
            are building a robust ecosystem that transforms how accommodations
            are found and managed. */}
          </p>
          <div className="SocialIcons" style={{ marginTop: "15px" }}>
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/company/pg-dekho"
                  target="_blank"
                >
                  <i className="fa-brands fa-linkedin" />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/pgdekhoindia" target="_blank">
                  <i className="fa-brands fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/pgdekhoindia/"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/pgdekhoindia/"
                  target="_blank"
                >
                  <i className="fa-brands fa-pinterest" />
                </a>
              </li>
              <li>
                <a href="https://x.com/pgdekhoindia" target="_blank">
                  <i className="fa-brands fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@PgDekhoIndia" target="_blank">
                  <i className="fa-brands fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="FooterLinks">
          <h5>COMPANY</h5>
          <ul>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms & Conditions</Link>{" "}
            </li>
            {/* <li><a href="blog.html">Blogs</a></li> */}
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <a style={{ cursor: "pointer" }} onClick={() => handleScroll()}>
                Testimonials
              </a>{" "}
            </li>
            {/* <li>
              <a href="#">Contact Us</a>
            </li> */}
          </ul>
        </div>
        <div className="FooterLinks">
          <h5>MEDIA</h5>
          <ul>
            <li>
              <Link to="/faq">FAQs</Link>
            </li>
            {/* <li>
              <a href="#">Blog</a>
            </li> */}
            {/* <li><a href="blog.html">Blogs</a></li> */}
            {/* <li>
              <a href="#">Carriers</a>
            </li> */}
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="FooterLinks">
          <h5>Download App Now</h5>
          <ul>
            <figure className="d-flex flex-wrap">
              <img
                src={require("../assets/images/android.png")}
                alt=""
                style={{ width: "100px", marginRight: "4px" }}
              />
              <img
                src={require("../assets/images/ios.png")}
                alt=""
                style={{ width: "100px" }}
              />
            </figure>
            <div className="d-flex">
              <img src={qrimg} style={{ width: "60px" }} />
              <p style={{ marginLeft: "15px" }}>
                Open camera & scan the Qr code to Download the app
              </p>
            </div>
          </ul>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <p>
          Copyright @ 2024 | All Rights Reserved by Roomer Technologies Pvt.
          Ltd.
        </p>
        <p>
          Images are representational purpose only; amenities shown may vary by
          properties.
        </p>
      </div>
    </footer>

    // <footer>
    //   <div class="footer">
    //     <ul>
    //     <li>
    //         {/* Address: Magnum Global Park, <br />
    //         Sector 58, Gurugram, Haryana, <br />
    //         122011, India
    //         <br /> */}
    //         Email: info@pgdekhoindia.in
    //       </li>
    //     </ul>
    //     <ul>

    //       <li>
    //         <Link to="/about-us">About Us</Link>
    //       </li>
    //       <li>
    //         <Link to="/terms-condition">Terms & Conditions</Link>
    //       </li>
    //       <li>
    //         <Link to="/privacy-policy">Privacy Policy</Link>
    //       </li>
    //       <li>
    //         <a style={{ cursor: "pointer" }} onClick={() => handleScroll()}>
    //           Testimonials
    //         </a>
    //       </li>
    //       <li>
    //         <Link to="/faq">FAQs</Link>
    //       </li>
    //       {/* <li>
    //             <a href="#">Contact Us</a>
    //         </li> */}
    //     </ul>
    //     <figure>
    //       <img src={require("../assets/images/android.png")} alt="" style={{width:"100px"}}/>
    //       <img src={require("../assets/images/ios.png")} alt="" style={{width:"100px"}}/>
    //     </figure>
    //   </div>
    //   <div class="last-footer">
    //     <figcaption>
    //       <p>
    //         Copyright @ 2024 | All Rights Reserved by Roomer Technologies Pvt.
    //         Ltd.
    //       </p>
    //     </figcaption>
    //     <ul>
    //       <li>
    //         <a>
    //           <img src={require("../assets/images/facbook.png")} alt="" />
    //         </a>
    //       </li>
    //       <li>
    //         <a>
    //           <img src={require("../assets/images/insta.png")} alt="" />
    //         </a>
    //       </li>
    //       <li>
    //         <a>
    //           <img src={require("../assets/images/tweter.png")} alt="" />
    //         </a>
    //       </li>

    //       <li>
    //         <a>
    //           <img src={require("../assets/images/Linkedin.png")} alt="" />
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // </footer>
  );
};

export default Footer;
