import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AutoPlace from "./CitySearch";
import { fetchPglisting } from "../reduxToolKit/slices/pgSlices/pgListingApiSlice";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { FaTv } from "react-icons/fa";
import { FaBed } from "react-icons/fa";
import { TbAirConditioning } from "react-icons/tb";
import { GiSlicedBread } from "react-icons/gi";
import { PiPicnicTableBold } from "react-icons/pi";
import { BiSolidBlanket } from "react-icons/bi";
import { FaMattressPillow } from "react-icons/fa6";
import { LuBedDouble } from "react-icons/lu";
import { MdOutlineChair } from "react-icons/md";
import { GiThermometerHot } from "react-icons/gi";
import { FaLongArrowAltRight } from "react-icons/fa";
import moment from "moment";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { addFavApi } from "../reduxToolKit/slices/mySlice/addFovSlice";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { url } from "../config/config";
import { BsGenderAmbiguous } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import Loader from "./Loader";
import { setSearchState } from "../reduxToolKit/slices/searchSlice";
import { isLoggedIn } from "../utils";

const roomAmenitiesArray = [
  {
    name: "Television",
    img: <FaTv style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Single Bed",
    img: <FaBed style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "AC/ Heating",
    img: <TbAirConditioning style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Mattress + Pillow",
    img: <FaMattressPillow style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Table + Chair",
    img: <MdOutlineChair style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Blanket/Quilt",
    img: <BiSolidBlanket style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Side Table",
    img: <PiPicnicTableBold style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Queen Bed",
    img: <LuBedDouble style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Hot Water",
    img: <GiThermometerHot style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
  {
    name: "Cupboard",
    img: <GiSlicedBread style={{ color: "#FD701E", fontSize: "22px" }} />,
  },
];

//"availableFor": "Co-Living", //"Boys", "Girls", "Co-Living"

const SearchResult = () => {
  const latestRequestRef = useRef(0);
  const initialState = {
    verified: true,
    name: "",
    lat: "",
    long: "",
    gender: "",
    roomType: "",
    food: "",
    postBy: "",
    parking: "",
    sort: "",

    city: "",
    locality: "",
    street: "",
    address: "",
    availability: "",
    roomType: [],
    location: {
      type: "point",
      coordinates: [],
    },
  };
  const navigate = useNavigate();
  const [cityArray, setCityArray] = useState([]);

  const [searchAddress, setSearchAddress] = useState([]);
  const searchState = useSelector((state) => state.searchReducer);
  console.log('Seach slice on search result page ==>', searchState)
  const { addressArray } = useSelector((state) => state.searchReducer);

  const { profileData } = useSelector((state) => state.profileReducer);

  const { pgData } = useSelector((state) => state.pgListing);
  const loader = useSelector((state) => state.pgListing.status);
  const [propertyName, setPropertyName] = useState("");
  const [citylist, setCitylist] = useState([]);
  const [selectCity, setSeclectCity] = useState(searchState?.selectCity);
  const [pageNo, setPageNo] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [readMore, setReadMore] = useState(false);
  const [localPGdata, setLocalPgData] = useState([]);
  const dispatch = useDispatch();
  const [budgetRange, setBudgetRange] = useState([1000, 20000]);
  const [iState, updateState] = useState(initialState);

  const {
    address,
    city,
    gender,
    food,
    roomType,
    postBy,
    availability,
    parking,
    sort,
    verified,
  } = iState;

  const fetchCityList = async (setCitylist) => {
    const response = await axios.get(`${url}user/getCities`, {});
    try {
      if (response.status) {
        setCitylist(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCityList(setCitylist);
  }, []);

  // console.log('This is city array in search result ', cityArray)

  // useEffect(() => {
  //   setCityArray(searchState?.cityArray || []);
  //   setSearchAddress(searchState?.addressArray || []);
  // }, [searchState.cityArray, searchState.addressArray]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
    dispatch(
      setSearchState({
        iState: { ...iState, [name]: value },
        cityArray: cityArray,
        addressArray: searchAddress,
        selectCity: selectCity,
        budgetRange: budgetRange,
      })
    );

    if (name == "sort") {
      updateState({ ...iState, [name]: value });
    }
  };

  const handleSearchbyName = (e) => {
    setPropertyName(e.target.value);
  };

  const handleCheckbox = (e, getter = [], prop) => {
    const { checked, name } = e.target;

    // Ensure getter is an array
    const updatedFilters = checked
      ? [...(getter || []), prop] // Add selected filter
      : (getter || []).filter((item) => item !== prop); // Remove unselected filter

    updateState((prevState) => ({
      ...prevState,
      [name]: updatedFilters,
    }));

    // Call the filter function after state update
    handleFilterPgData();
  };

  // const handleCheckbox = (e, getter, prop) => {
  //   const { checked, name } = e.target;
  //   if (checked) {
  //     updateState({ ...iState, [name]: [...getter, prop] });
  //   } else {
  //     let tempArray = getter;
  //     let index = tempArray.findIndex((item) => item == prop);
  //     tempArray.splice(index, 1);
  //     updateState({ ...iState, [name]: tempArray });
  //   }
  // };

  const filterAllMeal = (e) => {
    const { checked } = e.target;

    updateState((prevState) => ({
      ...prevState,
      food: checked ? ["Breakfast", "Lunch", "Dinner"] : [],
    }));

    // Call the filter function
    handleFilterPgData();
  };

  // const filterAllMeal = (e) => {
  //   const { checked } = e.target;

  //   if (checked) {
  //     updateState({ ...iState, food: ["Breakfast", "Lunch", "Dinner"] });
  //   } else {
  //     updateState({ ...iState, food: [] });
  //   }
  // };

  const filterBothTenUser = (e) => {
    const { checked } = e.target;

    updateState((prevState) => ({
      ...prevState,
      postBy: checked ? ["Agent", "Owner"] : [],
    }));

    // Call the filter function
    handleFilterPgData();
  };

  // const filterBothTenUser = (e) => {
  //   const { checked } = e.target;

  //   if (checked) {
  //     updateState({ ...iState, postBy: ["Agent", "Owner"] });
  //   } else {
  //     updateState({ ...iState, postBy: [] });
  //   }
  // };

  const handleRadio = (e, setter, prop) => {
    updateState((prevState) => ({
      ...prevState,
      [setter]: e.target.checked ? prop : "",
    }));

    // Call the filter function
    handleFilterPgData();
  };

  // const handleRadio = (e, setter, prop) => {
  //   if (e.target.checked) {
  //     updateState({ ...iState, [setter]: prop });
  //   } else {
  //     updateState({ ...iState, [setter]: "" });
  //   }
  // };

  const handleVerify = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      updateState({ ...iState, verified: e.target.checked });
    } else {
      updateState({ ...iState, verified: e.target.checked });
      // dispatch(
      //   fetchPglisting({
      //     userId: profileData?.data?._id,
      //     verified: e.target.checked,
      //   })
      // );
    }
  };

  useEffect(() => {
    let tempArray = [];

    tempArray = pgData?.data?.map((item) => {
      return { ...item, isFav: item?.isFavorite };
    });

    setLocalPgData(tempArray);

    // console.log("PG DATA=========>, ", pgData);
  }, [pgData]);

  const handleFav = (e, index) => {
    e.stopPropagation();

    if (isLoggedIn("pgUser")) {
      let tempArray = [...localPGdata];

      let obj = tempArray[index];

      obj.isFav = obj.isFav ? false : true;

      tempArray[index] = obj;

      dispatch(addFavApi({ type: "PG", propId: obj._id }));

      setLocalPgData(tempArray);
    } else {
      navigate("/login");
    }
  };

  // const handleRemove = (i) => {
  //   let tempArray = [...cityArray];
  //   let addressArray = [...searchAddress];
  //   tempArray.splice(i, 1);
  //   addressArray.splice(i, 1);
  //   setSearchAddress(addressArray);
  //   setCityArray(tempArray);
  // };

  const handleRemove = (i) => {
    console.log("Handle remove ran ===>");
    const updatedCityArray = [...cityArray];
    const updatedAddressArray = [...searchAddress];

    updatedCityArray.splice(i, 1);
    updatedAddressArray.splice(i, 1);

    // Update local state
    setCityArray(updatedCityArray);
    setSearchAddress(updatedAddressArray);

    // Update redux search state
    dispatch(
      setSearchState({
        ...searchState,
        cityArray: updatedCityArray,
        addressArray: updatedAddressArray,
      })
    );

    // Optionally, trigger the API call directly:
    // handleFilterPgData();
  };

  // const handleFilterPgData = () => {
  //   let viewType = searchState?.iState?.viewType;
  //   dispatch(
  //     fetchPglisting({
  //       // location: searchAddress?.map((elem)=>elem),
  //       location:
  //         searchAddress?.length > 0
  //           ? searchAddress
  //           : searchState?.addressArray?.length === 0
  //           ? []
  //           : searchState?.addressArray?.map((elem) => elem),
  //       userId: profileData?.data?._id,
  //       gender: iState?.gender ? iState?.gender : searchState?.iState?.gender,
  //       city: selectCity ? selectCity : searchState?.selectCity,
  //       budget: {
  //         min: budgetRange[0],
  //         max: budgetRange[1],
  //       },
  //       availability: iState?.availability,
  //       food: iState?.food,
  //       sort: iState?.sort,
  //       postBy: iState?.postBy,
  //       parking: iState?.parking,
  //       verified: iState?.verified,
  //       roomType: iState?.roomType ? iState?.roomType : searchState?.roomType,
  //       search: propertyName,
  //       needs: searchState?.need ? searchState?.need : "",
  //       [viewType]: searchState?.iState?.viewall ? true : "",
  //       page: pageNo,
  //     })
  //   );
  // };

  const handleFilterPgData = () => {
    // Increase the request ID
    const currentRequest = ++latestRequestRef.current;

    dispatch(
      fetchPglisting({
        location:
          searchAddress?.length > 0
            ? searchAddress
            : searchState?.addressArray || [],
        userId: profileData?.data?._id,
        gender: iState?.gender || searchState?.iState?.gender,
        city: selectCity || searchState?.selectCity,
        budget: { min: budgetRange[0], max: budgetRange[1] },
        availability: iState?.availability,
        food: iState?.food,
        sort: iState?.sort,
        postBy: iState?.postBy,
        parking: iState?.parking,
        verified: iState?.verified,
        roomType: iState?.roomType || searchState?.roomType,
        search: propertyName,
        needs: searchState?.needs || "",
        // ... include any other parameters,
        page: pageNo,
      })
    ).then((response) => {
      // Only update if this response belongs to the latest request
      if (currentRequest === latestRequestRef.current) {
        // (Your code that updates local PG data or other UI)
        // For example, you might do nothing here if your redux store already holds pgData.
      }
    });
  };
  // const [count, setCount] = useState(0);
  //   useEffect(() => {
  //   if (count > 0) {
  //     handleFilterPgData();
  //   }
  //   setTimeout(()=>{ setCount((prev) => prev + 1)},1000)
  //  // Increase count after the first render
  // }, [iState, budgetRange, propertyName, pageNo, searchAddress, searchState]);

  // console.log("empty log");
  // useEffect(() => {
  //   handleFilterPgData();
  // }, [searchAddress]);

  useEffect(() => {
    // console.log("useEffect ran====> ", iState);
    handleFilterPgData();
  }, [iState, budgetRange, propertyName, pageNo, searchAddress]);

  // console.log('This is search address  ===> ',searchAddress)

  // useEffect(() => {
  //   handleFilterPgData();
  // }, [searchState.addressArray]);

  // console.log("empty log 2");

  // useEffect(() => {
  //   updateState(searchState?.iState || initialState);
  //   setCityArray(
  //     searchState?.cityArray?.length === 0 ? [] : searchState?.cityArray
  //   );
  //   setSearchAddress(
  //     searchState?.addressArray?.length === 0 ? [] : searchState?.addressArray
  //   );
  //   setSeclectCity(searchState?.selectCity);
  //   setBudgetRange([
  //     searchState?.budgetRange?.min || 1000,
  //     searchState?.budgetRange?.max || 20000,
  //   ]);
  // }, [searchState]);

  // useEffect(() => {
  //   updateState(searchState?.iState);
  //   setCityArray(searchState?.cityArray?.length==0?[]:searchState?.cityArray);
  //   setSearchAddress(searchState?.addressArray?.length==0?[]: searchState?.addressArray?.map((elem)=>elem));
  //   setSeclectCity( searchState?.selectCity);
  //   setBudgetRange([searchState?.budgetRange?.min ? searchState?.budgetRange?.min : 1000,searchState?.budgetRange?.max? searchState?.budgetRange?.max:20000]);
  // }, [searchState]);
  useEffect(() => {
    updateState(searchState?.iState || initialState);
    setCityArray(searchState?.cityArray || []);
    setSearchAddress(searchState?.addressArray || []);
    setSeclectCity(searchState?.selectCity);
    setBudgetRange([
      searchState?.budgetRange?.min || 1000,
      searchState?.budgetRange?.max || 20000,
    ]);
  }, [searchState]);

  // useEffect(() => {
  //   updateState(searchState?.iState || initialState);
  //   setCityArray(
  //     searchState?.cityArray?.length === 0 ? [] : searchState?.cityArray
  //   );
  //   setSearchAddress(
  //     searchState?.addressArray?.length === 0
  //       ? []
  //       : searchState?.addressArray?.map((elem) => elem)
  //   );
  //   setSeclectCity(searchState?.selectCity);
  //   setBudgetRange([
  //     searchState?.budgetRange?.min ? searchState?.budgetRange?.min : 1000,
  //     searchState?.budgetRange?.max ? searchState?.budgetRange?.max : 20000,
  //   ]);
  // }, [searchState]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    setPageNo(pageNumber);
    window.scrollTo(0, 0);
  };

  const handeleMap = (e, item, scroll) => {
    // e.preventDefault();
    e.stopPropagation();

    dispatch(
      setSearchState({
        iState: iState,
        cityArray: cityArray,
        addressArray: searchAddress,
        selectCity: selectCity,
        budgetRange: budgetRange,
      })
    );
    let item1 = {
      ...item,
      scroll: scroll,
      iState: iState,
      cityArray: cityArray,
      addressArray: searchAddress,
      selectCity: selectCity,
      budgetRange: budgetRange,
    };
    navigate(`/pg-details?id=${item?._id}`, { state: item1 });
  };

  // console.log(pgData, "PGDATA ==============>");

  return (
    <>
      {/* {loader!="succeeded"?<Loader/>:""} */}
      <Header />
      <ToastContainer />
      <section class="search-header">
        <div class="Filter">
          <div class="form-group">
            <div class="gender customSelect">
              {/* <span>
                <img src={require("../assets/images/location.png")} alt="" />
              </span> */}
              <span>
                <IoLocationOutline fontSize={"22px"} color="#fd701e" />
              </span>
              <div className="newSelect">
                {/* <select
                  onChange={(e) => {
                    setSeclectCity(e.target.value);
                    setSearchAddress([]);
                    // Clear Redux addressArray as well:
                    dispatch(setSearchState({ addressArray: [] }));
                  }}
                  name="selectCity"
                  value={selectCity}
                > */}

                <select
                  onChange={(e) => {
                    const newCity = e.target.value;
                    setSeclectCity(newCity);
                    setSearchAddress([]); // clear local address array
                    dispatch(
                      setSearchState({ selectCity: newCity, addressArray: [] })
                    ); // update Redux state
                  }}
                  name="selectCity"
                  value={selectCity}
                >
                  <option value={""}>Select city</option>
                  {citylist?.map((elem, id) => {
                    return (
                      <option key={id} value={elem?.name}>
                        {elem?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div class="form-group CustomOne" style={{ position: "relative" }}>
            <div class="loaction">
              {/* <span>
                <img src={require("../assets/images/location.png")} alt="" />
              </span> */}
              <span>
                <IoLocationOutline fontSize={"22px"} color="#fd701e" />
              </span>
              <AutoPlace
                updateState={updateState}
                iState={iState}
                address={address}
                cityArray={cityArray}
                // cityArray={addressArray}  // uncomment if you want to send the value of cityArray which is in redux
                setCityArray={setCityArray}
                searchAddress={searchAddress}
                setSearchAddress={setSearchAddress}
                selectCity={selectCity}
              />{" "}
            </div>
            <div className="ExpertiseList">
              <ul>
                {addressArray &&
                  addressArray?.map((item, i) => (
                    <li key={i}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id={`tooltip-${i}`}>{item}</Tooltip>}
                      >
                        <div style={{ cursor: "pointer" }}>
                          {item?.split(",")[0].slice(0, 9)}...{" "}
                          <span
                            onClick={() => handleRemove(i)}
                            style={{ cursor: "pointer" }}
                          >
                            &times;
                          </span>{" "}
                        </div>
                      </OverlayTrigger>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div class="form-group">
            <div class="gender customSelect">
              {" "}
              {/* <img src={require("../assets/images/gender.png")} alt="" /> */}
              <span>
                <BsGenderAmbiguous fontSize={"22px"} color="#fd701e" />
              </span>
              <div className="newSelect">
                <select onChange={handleChange} name="gender" value={gender}>
                  <option value={""}>Gender</option>
                  <option value={"Boys"}>Boys</option>
                  <option value={"Girls"}>Girls</option>
                  <option value={"Co-living"}>Co-Living</option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group CustomTwo">
            <div class="PriceSlideBox">
              <aside>
                {/* <span>
                  <img src={require("../assets/images/rs.png")} alt="" />
                </span> */}
                <span>
                  <MdOutlineCurrencyRupee fontSize={"22px"} color="#fd701e" />
                </span>
                <label>Rent Range</label>
              </aside>
              <Slider
                allowCross={false}
                range
                min={500}
                step={500}
                value={budgetRange}
                max={50000}
                onChange={(e) => {
                  setBudgetRange(e);
                }}
              />
            </div>
          </div>

          {/* <div class="form-group">
            <a onClick={()=>handleFilterPgData()} class="Button">
            Update
            <FaLongArrowAltRight style={{fontSize:"20px", color:"#fff"}}/>
            </a>
          </div> */}
        </div>
      </section>

      <section>
        <div class="searching">
          <div class="row">
            <div class="col-sm-3">
              <div class="FilterArea">
                <div class="Filterbar">
                  <div class="form-group">
                    <label>Search by property name</label>
                    <div class="filterbarArea">
                      <span>
                        <img
                          src={require("../assets/images/search.png")}
                          alt=""
                        />{" "}
                      </span>
                      <input
                        type="search"
                        onChange={handleSearchbyName}
                        value={propertyName}
                        class="form-control"
                        placeholder="search by pg name"
                      />
                    </div>
                  </div>
                </div>
                <h2>Filter by</h2>
                <div class="FilterBox">
                  <article>
                    <h3>Occupancy</h3>
                  </article>
                  <aside>
                    <ul>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Single Room
                          <input
                            type="checkbox"
                            name="roomType"
                            checked={roomType?.includes("Single Bed")}
                            onChange={(e) =>
                              handleCheckbox(e, roomType, "Single Bed")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Double Sharing
                          <input
                            type="checkbox"
                            name="roomType"
                            checked={roomType?.includes("Double Bed")}
                            onChange={(e) =>
                              handleCheckbox(e, roomType, "Double Bed")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Triple Sharing
                          <input
                            type="checkbox"
                            name="roomType"
                            checked={roomType?.includes("Triple Bed")}
                            onChange={(e) =>
                              handleCheckbox(e, roomType, "Triple Bed")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          other
                          <input
                            type="checkbox"
                            name="roomType"
                            checked={roomType?.includes("Four Bed")}
                            onChange={(e) =>
                              handleCheckbox(e, roomType, "Four Bed")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </aside>
                </div>
                <div class="FilterBox">
                  <article>
                    <h3>Food</h3>
                  </article>
                  <aside>
                    <ul>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          All meals Included
                          <input type="checkbox" onChange={filterAllMeal} />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Breakfast
                          <input
                            type="checkbox"
                            name="food"
                            checked={food?.includes("Breakfast")}
                            onChange={(e) =>
                              handleCheckbox(e, food, "Breakfast")
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Lunch
                          <input
                            type="checkbox"
                            name="food"
                            checked={food?.includes("Lunch")}
                            onChange={(e) => handleCheckbox(e, food, "Lunch")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Dinner
                          <input
                            type="checkbox"
                            name="food"
                            checked={food?.includes("Dinner")}
                            onChange={(e) => handleCheckbox(e, food, "Dinner")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </aside>
                </div>

                <div class="FilterBox">
                  <article>
                    <h3>Parking</h3>
                  </article>
                  <aside>
                    <ul>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Bike
                          <input
                            type="checkbox"
                            checked={parking == "Bike" ? true : false}
                            onChange={(e) => handleRadio(e, "parking", "Bike")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Car
                          <input
                            type="checkbox"
                            checked={parking == "Car" ? true : false}
                            onChange={(e) => handleRadio(e, "parking", "Car")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Both
                          <input
                            type="checkbox"
                            checked={parking == "Both" ? true : false}
                            onChange={(e) => handleRadio(e, "parking", "Both")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </aside>
                </div>

                <div class="FilterBox">
                  <article>
                    <h3>Posted By</h3>
                  </article>
                  <aside>
                    <ul>
                      {/* <li>
                        <label class="CheckBox">
                          {" "}
                          Admin
                          <input
                            type="checkbox"
                            checked={postBy == "Admin" ? true : false}
                            onChange={(e) => handleRadio(e, "postBy", "Admin")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li> */}
                      <li>
                        <label class="CheckBox">
                          {" "}
                          Owner
                          <input
                            type="checkbox"
                            name="postBy"
                            checked={postBy?.includes("Owner")}
                            onChange={(e) => handleCheckbox(e, postBy, "Owner")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Agent
                          <input
                            type="checkbox"
                            name="postBy"
                            checked={postBy?.includes("Agent")}
                            onChange={(e) => handleCheckbox(e, postBy, "Agent")}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                      <li>
                        <label class="CheckBox">
                          Both
                          <input
                            type="checkbox"
                            // checked={postBy == "both" ? true : false}
                            onChange={filterBothTenUser}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </li>
                    </ul>
                  </aside>
                </div>
              </div>
            </div>

            <div class="col-sm-9">
              <div class="right-search-area">
                <div class="right-search-box">
                  <aside>
                    <h3>
                      PG : {pgData?.metadata?.[0]?.total} search results found
                    </h3>

                    <div class="verify">
                      <div class="Actions">
                        Verified Properties &nbsp;
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={verified}
                            onChange={handleVerify}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>

                      <div class="form-group Recommended">
                        <label>Sort by</label>
                        <select
                          onChange={handleChange}
                          value={sort}
                          name="sort"
                          class="form-control"
                        >
                          <option value="">Recommended</option>
                          <option value="Price low to high">
                            Price Low to High
                          </option>
                          <option value="Price high to low">
                            Price High to Low
                          </option>
                          <option value="Rate high to low">
                            Rating High to Low
                          </option>
                          <option value="Rate low to high">
                            Rating Low to High
                          </option>
                        </select>
                      </div>
                    </div>
                  </aside>
                </div>

                {localPGdata?.map((item, i) => {
                  return (
                    <a onClick={(e) => handeleMap(e, item, false)}>
                      <div class="luxury-area">
                        <div class="luxury">
                          <figcaption style={{ position: "relative" }}>
                            <h3>
                              {item?.name}{" "}
                              <span>
                                {item?.address} , (near-{item?.street})
                              </span>
                            </h3>
                            <div>
                              <span span class="favboy1">
                                <ul class="">
                                  <li style={{ zIndex: 9999 }}>
                                    <a onClick={(e) => handleFav(e, i)}>
                                      {item?.isFav ? (
                                        <img
                                          src={require("../assets/images/heart-red.png")}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={require("../assets/images/heart.png")}
                                          alt=""
                                        />
                                      )}
                                    </a>
                                  </li>
                                </ul>
                              </span>
                            </div>
                            <p>
                              {readMore
                                ? item?.description
                                : item?.description?.slice(0, 185)}{" "}
                              <a
                                onClick={() => {
                                  readMore
                                    ? setReadMore(false)
                                    : setReadMore(true);
                                }}
                              >
                                Read More...
                              </a>
                            </p>
                          </figcaption>

                          <ul class="flatOption">
                            {item?.pgRooms?.filter(
                              (room) => room?.roomType == "Single Bed"
                            )?.length > 0 && (
                              <li>
                                <h6>
                                  Rs.{" "}
                                  {
                                    item?.pgRooms?.filter(
                                      (room) => room?.roomType == "Single Bed"
                                    )?.[0]?.monthlyRent
                                  }
                                </h6>
                                <p>Single Room </p>
                              </li>
                            )}

                            {item?.pgRooms?.filter(
                              (room) => room?.roomType == "Double Bed"
                            )?.length > 0 && (
                              <li>
                                <h6>
                                  Rs.{" "}
                                  {
                                    item?.pgRooms?.filter(
                                      (room) => room?.roomType == "Double Bed"
                                    )?.[0]?.monthlyRent
                                  }
                                </h6>
                                <p>Double Sharing </p>
                              </li>
                            )}
                            {item?.pgRooms?.filter(
                              (room) => room?.roomType == "Triple Bed"
                            )?.length > 0 && (
                              <li>
                                <h6>
                                  Rs.{" "}
                                  {
                                    item?.pgRooms?.filter(
                                      (room) => room?.roomType == "Triple Bed"
                                    )?.[0]?.monthlyRent
                                  }
                                </h6>
                                <p>Triple Sharing </p>
                              </li>
                            )}

                            {item?.pgRooms?.filter(
                              (room) => room?.roomType == "Four Bed"
                            )?.length > 0 && (
                              <li>
                                <h6>
                                  Rs.{" "}
                                  {
                                    item?.pgRooms?.filter(
                                      (room) => room?.roomType == "Four Bed"
                                    )?.[0]?.monthlyRent
                                  }
                                </h6>
                                <p>Others</p>
                              </li>
                            )}
                          </ul>
                        </div>
                        <hr />

                        <div class="search-body">
                          <div class="search-verified">
                            {item?.verified && (
                              <span class="green-verfied">
                                <img
                                  src={require("../assets/images/verified.png")}
                                  alt=""
                                />{" "}
                                Verified
                              </span>
                            )}
                            <span span class="favboy">
                              <ul class="PreferredList">
                                {/* <li>
                          <a >
                            <img
                              src={require("../assets/images/shere.png")}
                              alt=""
                            />
                          </a>
                        </li> */}
                              </ul>
                            </span>{" "}
                            <span class="boy">{item?.availableFor}</span>
                            <div class="left-search-verified-flat">
                              <img
                                src={
                                  item?.propertyMedia?.filter(
                                    (elem) => elem.default
                                  )?.[0]?.mediaUrl
                                }
                                // src={
                                //   item?.propertyMedia?.length > 0
                                //     ? item?.propertyMedia?.[0]?.mediaUrl
                                //     : require("../assets/images/search1.png")
                                // }
                                alt=""
                              />
                            </div>
                            <div class="right-search-verified">
                              <ul className="postby-des">
                                {item?.postBy} Uploaded
                              </ul>
                              <div
                                class="HotelDetailsArea"
                                style={{ padding: "10px 0px 0" }}
                              >
                                <div class="HotelDetailsRight HotelDetailsRight2">
                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <p>
                                            Preferred Tenant
                                            <span>{item?.preferredGuest}</span>
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            Available for{" "}
                                            <span>{item?.availableFor}</span>
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            Laundary Services{" "}
                                            <span>
                                              {" "}
                                              {item?.isLaundryService
                                                ? "Available"
                                                : "Not Available"}
                                            </span>
                                          </p>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <p>
                                            Available From
                                            <span>
                                              {" "}
                                              {moment(
                                                item?.availableFrom
                                              ).format("DD/MM/YYYY")}
                                            </span>
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            Ac Room{" "}
                                            <span>
                                              {" "}
                                              {item?.roomAmenities?.some(
                                                (ele) =>
                                                  ele?.includes("AC/ Heating")
                                              )
                                                ? "Available"
                                                : "Not Available"}
                                            </span>
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            Parking{" "}
                                            <span>
                                              {" "}
                                              {item?.parking
                                                ? item?.vehicleType == "Both"
                                                  ? "Car,Bike"
                                                  : item?.vehicleType
                                                : "Not Available"}
                                            </span>
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="d-flex justify-content-between">
                                    <ul className="facility-area">
                                      {item?.roomAmenities
                                        ?.slice(0, 6)
                                        ?.map((ele) => (
                                          <li>
                                            <span>
                                              {
                                                roomAmenitiesArray?.filter(
                                                  (roomAmenity) =>
                                                    roomAmenity?.name == ele
                                                )?.[0]?.img
                                              }
                                            </span>
                                          </li>
                                        ))}
                                      <li>
                                        <small>+ More</small>
                                      </li>
                                    </ul>
                                    <div class="view-contact">
                                      <a class="Button">View Contact</a>
                                      <a
                                        onClick={(e) =>
                                          handeleMap(e, item, true)
                                        }
                                        class="viewbutton"
                                        style={{ cursor: "pointer" }}
                                      >
                                        View on map{" "}
                                        <img
                                          src="images/orng-arrow.png"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </a>
                  );
                })}
                <div>
                  {pgData?.metadata?.[0]?.total > 0 ? (
                    <div className="Pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={pgData?.metadata?.[0]?.total}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <figure>
                  <img src={require("../assets/images/rating.png")} alt="" />
                </figure> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="download download2">
          <figure>
            <img
              class="download-image"
              src={require("../assets/images/banner-pg.png")}
              alt=""
            />
          </figure>

          <span>
            <h3 style={{ color: "#000" }}>
              Download the mobile application for bonus <br /> coupons and
              travel codes
            </h3>
            <img
              src={require("../assets/images/android.png")}
              alt=""
              style={{ width: "100px" }}
            />
            <img
              src={require("../assets/images/ios.png")}
              alt=""
              style={{ width: "100px" }}
            />
          </span>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SearchResult;
