import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
// import { HiBuildingOffice2 } from "react-icons/hi2";
import locImg from "../assets/images/map-loc-img.png"

// Container style for the map
const containerStyle = {
  width: '100%',
  height: '400px'
};

// Default center coordinates for the map (if props are not yet available)
const defaultCenter = {
  lat: 28.627981,
  lng: 77.3648567
};

const MapComponent = ({lat,lng}) => {
    const [map, setMap] = useState(null);

  // Load Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB0oVP9ASJypwf7BLW86zTwqqM2iSI0DuE' // Replace with your actual Google Maps API key
    // googleMapsApiKey: 'AIzaSyBDI26u2BD-qaKwIavB-t_Fka6nHbVK1CA' // Replace with your actual Google Maps API key
  });

  // Callback to handle map load
  const onLoad = useCallback(map => {
    setMap(map);
  }, []);

  // Callback to handle map unmount
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // The map center will be updated whenever lat or lng changes
  const mapCenter = {
    lat: lat || defaultCenter.lat,
    lng: lng || defaultCenter.lng
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Marker for the dynamic location */}
      <Marker
        position={mapCenter}
        // label="Location"
        icon={locImg}
      />
    </GoogleMap>
  ) : <div>Loading map...</div>;
};

export default MapComponent;
