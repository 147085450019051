import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,Link } from 'react-router-dom';
import locImg from "../../assets/images/map-loc-img.png";
import { RiSecurePaymentFill } from "react-icons/ri";
import { TbBrandBooking } from "react-icons/tb";
import { RiCustomerServiceFill } from "react-icons/ri";
import axios from "axios";
import { url } from "../../config/config";
import { isLoggedIn } from "../../utils";
import { toast, ToastContainer } from "react-toastify";
import { fetchPgDetails } from "../../reduxToolKit/slices/pgSlices/pgDetailsSlice";
import { completePgApi } from "../../reduxToolKit/slices/pgSlices/completePgSlice";
import { completeUpgradePg } from "../../reduxToolKit/slices/pgSlices/completeUpgradePgSlice";
import { completeUpgradeFlat } from "../../reduxToolKit/slices/pgSlices/completeUpgradeFlatSlice";

const UpgradePlan = ({planModal, setPlanModal,pgDetail}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = JSON.parse(window.localStorage.getItem("pgUser"));
    // const [SubscriptionModal, setSubscription] = useState(planModal);
    const [gst, setGst] = useState();
    const [gstError, setGstError] = useState(false);
    const [planList, setPlanList] = useState();
    const [planDetails, setPlanDetails] = useState();
    const [isMonthly, setIsMonthly] = useState(false);
    // const handleSubscriptionModalClose = () => setSubscription(false);
    // const handleSubscriptionModalShow = (id) => {
    //   setSubscription(true);
    // };
  
    const [PaymentModal, setPayment] = useState(false);
    const handlePamentModalClose = () => setPayment(false);
    const handlePamentModalShow = (elem) => {
      setPayment(true);
      setPlanDetails(elem);
    };
  
    const [termsCheck, setTermsCheck] = useState(true);
  
    const { pgDetails } = useSelector((state) => state.pgData);
   
    useEffect(() => {
      dispatch(fetchPgDetails());
      fetchPlanDetails();
    }, []);
  
    const fetchPlanDetails = async () => {
      try {
        const response = await axios.get(
          `${url}user/getPlansByUser`,
          {
            headers: {
              Authorization: isLoggedIn("pgUser"),
            },
          }
        );
        if (response?.data?.success) {
          setPlanList(response?.data?.data?.result);
        }
      } catch (error) {
        console.error("Error fetching plan list:", error);
        throw error; // Rethrow the error to be handled by the calling code
      }
    };
    const handleComplete = () => {
       if(!pgDetail?.BHKType){
        dispatch(completeUpgradePg({pgId: pgDetail?._id, })
      ).then((res) => {
        if (res.payload.success) {
          setPlanModal(false)
          setPayment(false)
          window.location.reload();
        }
      });
       }else{
        dispatch(completeUpgradeFlat({flatId: pgDetail?._id, })
      ).then((res) => {
        if (res.payload.success) {
          setPlanModal(false)
          setPayment(false)
          window.location.reload();
        }
      });
       }
      };
    
      const loadRazorpayScript = () => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
          script.onload = () => resolve(true);
          script.onerror = () => resolve(false);
          document.body.appendChild(script);
        });
      };
    
      const handlePayment = async () => {
        const res = await loadRazorpayScript();
        if (!termsCheck) {
          toast.warning("Please accept terms and coditions");
        } else {
          if (!res) {
            alert("Failed to load Razorpay SDK");
            return;
          }
    
          const response = await fetch(`${url}user/updatedSubscription`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: isLoggedIn("pgUser"),
            },
            body: JSON.stringify({
              userId: userDetails?._id, // Pass the current user ID to the backend
              planId: isMonthly?planDetails?.razorPayMonthlyId:planDetails.razorPayYearlyId, // Pass the Plan ID you created in Razorpay
              monthly: isMonthly,
              propertyId:pgDetail?._id,
              oldPLanId:pgDetail?.planId,
              propertyModel:pgDetail?.BHKType?"flats":"pgs"
            }),
          });
    
          const subscriptionData = await response.json();
    
          if (subscriptionData?.data?.subscriptionId) {
            const options = {
              // key: "rzp_test_t3BDOjSAeHzUvi", // Replace with your Razorpay key ID. This one is test mode key
              key: "rzp_live_iaNFM454Fyiivh", // Replace with your Razorpay key ID. This one is live mode key
              // amount: elem?.pricingInformation?.AnnualDiscountedRate, // Amount in paise (e.g., 50000 paise = INR 500)
              subscription_id: subscriptionData?.subscriptionId,
              currency: "INR",
              name: "PG Dekho",
              description: "Test Subscription Plan",
              image: locImg, // Replace with your company logo
              handler: function (response) {
                console.log(response.razorpay_payment_id);
                toast.success("Payment sccessfully");
                handleComplete();
              },
              prefill: {
                name: userDetails?.name,
                email: pgDetails?.data?.[0]?.contactNumber
                  ? pgDetails?.data?.[0]?.contactEmail
                  : userDetails?.email,
                contact: pgDetails?.data?.[0]?.contactNumber
                  ? pgDetails?.data?.[0]?.contactNumber
                  : userDetails?.phone,
              },
              // notes: {
              //   address: 'text address',
              // },
              theme: {
                color: "#FD701E",
              },
              // recurring: true,
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          } else if(subscriptionData?.data?.subscriptionId==null){
            toast.success("PG created successfully")
            handleComplete();
          }
          else {
            alert("Subscription creation failed.");
            return;
          }
        }
      
      };
    
      const handleGst = (e) => {
        const gstValue = e.target.value;
        const gstRegex = /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([A-Z0-9]{3})$/;
    
        // Allow partial input but only validate when the length is 15 characters
        setGst(gstValue); // Update the input value in the state
        setGstError(true);
        if (gstValue.length === 15) {
          if (gstRegex.test(gstValue)) {
            setGstError(false); // Valid GST
          } else {
            setGstError(true); // Invalid GST
          }
        }
      };
  return (
    <div>
         <Modal
        show={planModal}
        onHide={()=>setPlanModal(false)}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={()=>setPlanModal(false)}
              class="CloseModal"
              data-dismiss="modal"
              style={{ cursor: "pointer" }}
            >
              ×
            </a>
            <div class="TableHeader">
              <ul>
                <li>
                  <button class="OffButton">Save Upto 65% OFF</button>
                </li>
                <li>Yearly</li>
                <li>
                  <label class="switch" style={{ marginLeft: "15px" }}>
                    <input
                      type="checkbox"
                      checked={isMonthly}
                      onChange={() => setIsMonthly(!isMonthly)}
                    />
                    <span class="slider"></span>
                  </label>
                </li>
                <li>Monthly</li>
              </ul>
            </div>

            <div class="MonthYearTable">
              <table>
                <thead>
                  <tr>
                    <th>Plan Name</th>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <th>
                            <button>{elem?.basicInfo?.planName}</button>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Prices/month</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            {" "}
                            <sub>₹ {elem?.pricingInformation?.MonthlyRate}</sub>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Number of Properties</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <sub>{elem?.basicInfo?.NumberOfProperties}</sub>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Premium Listing</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <i
                              class={`${
                                elem?.features?.premiumList
                                  ? "fa-solid fa-check"
                                  : "fa-solid fa-xmark"
                              }`}
                            ></i>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Verified Property Tag</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <i
                              class={`${
                                elem?.features?.verifiedTag
                                  ? "fa-solid fa-check"
                                  : "fa-solid fa-xmark"
                              }`}
                            ></i>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Email &amp; Social Media Promotion</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <i
                              class={`${
                                elem?.features?.socialMediaProm
                                  ? "fa-solid fa-check"
                                  : "fa-solid fa-xmark"
                              }`}
                            ></i>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Professional Property Photoshoot</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <i
                              class={`${
                                elem?.features?.profPhotoshoot
                                  ? "fa-solid fa-check"
                                  : "fa-solid fa-xmark"
                              }`}
                            ></i>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Higher Position of Your Property in Search Results</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <i
                              class={`${
                                elem?.features?.higherPosition
                                  ? "fa-solid fa-check"
                                  : "fa-solid fa-xmark"
                              }`}
                            ></i>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Property Description by Experts</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <i
                              class={`${
                                elem?.features?.propertyDesc
                                  ? "fa-solid fa-check"
                                  : "fa-solid fa-xmark"
                              }`}
                            ></i>
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td>Annual Rate</td>
                    {planList
                      ?.filter((elem) => elem.status == "active")
                      ?.map((elem, id) => {
                        return (
                          <td>
                            <sub>₹ {elem?.pricingInformation?.AnnualRate}</sub>
                          </td>
                        );
                      })}
                    {/* <td>*</td>
                                            <td>$ <sub>10000</sub><br /><span>35% OFF</span></td>
                                            <td>$ <sub>15000</sub><br /><span>35% OFF</span></td>
                                            <td>$ <sub>20000</sub><br /><span>35% OFF</span></td>
                                            <td>$ <sub>30000</sub><br /><span>35% OFF</span></td> */}
                  </tr>
                </tbody>
                <tfoot>
                  {isMonthly ? (
                    <tr>
                      <td>Monthly Rate</td>
                      {planList
                        ?.filter((elem) => elem.status == "active")
                        ?.map((elem, id) => {
                          return (
                            <td>
                              <sub>
                                ₹ {elem?.pricingInformation?.MonthlyRate}
                              </sub>
                              <br />
                              <button
                                type="button"
                                onClick={() => handlePamentModalShow(elem)}
                                class="FolderPermissionId"
                                style={{ marginTop: "8px" }}
                              >
                                {" "}
                                Buy now
                              </button>
                            </td>
                          );
                        })}
                    </tr>
                  ) : (
                    <tr>
                      <td>Discounted Annual Rate</td>
                      {planList
                        ?.filter((elem) => elem.status == "active")
                        ?.map((elem, id) => {
                          return (
                            <td>
                              <sub>
                                ₹{" "}
                                {elem?.pricingInformation?.AnnualDiscountedRate}
                              </sub>
                              <>
                                <br />
                                <span>
                                  {(isNaN(
                                    (1 -
                                      elem.pricingInformation
                                        .AnnualDiscountedRate /
                                        elem.pricingInformation.AnnualRate) *
                                      100
                                  )
                                    ? 100
                                    : (1 -
                                        elem.pricingInformation
                                          .AnnualDiscountedRate /
                                          elem.pricingInformation.AnnualRate) *
                                      100
                                  ).toFixed(0)}
                                  % OFF
                                </span>
                              </>
                              <br />
                              <button
                                type="button"
                                onClick={() => handlePamentModalShow(elem)}
                                class="FolderPermissionId"
                              >
                                {" "}
                                Buy now
                              </button>
                            </td>
                          );
                        })}
                    </tr>
                  )}
                </tfoot>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={PaymentModal}
        onHide={handlePamentModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <div class="Category">
            <a
              onClick={handlePamentModalClose}
              class="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div class="PayNowDetails">
              <article>
                <aside>
                  <div class="PayNowDetailsLeft">
                    <h3>Book with confidence. Guaranteed.</h3>
                    <p>
                      You’re covered when you book and pay on Bindle.{" "}
                      <a href="javascript:void(0);">Learn more.</a>
                    </p>
                    <ul>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/bell-icon.svg")}
                          />
                        </span>
                        Act fast! Price and availability may change.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/non-refund.svg")}
                          />
                        </span>
                        Non-refundable.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/bell-icon.svg")}
                          />
                        </span>
                        Act fast! Price and availability may change.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/non-refund.svg")}
                          />
                        </span>
                        Non-refundable.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/bell-icon.svg")}
                          />
                        </span>
                        Act fast! Price and availability may change.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/non-refund.svg")}
                          />
                        </span>
                        Non-refundable.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/bell-icon.svg")}
                          />
                        </span>
                        Act fast! Price and availability may change.
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../../assets/images/non-refund.svg")}
                          />
                        </span>
                        Non-refundable.
                      </li>
                    </ul>
                  </div>
                </aside>
                <aside>
                  <div class="PayNowDetailsRight">
                    <div class="StandardPlan">
                      <aside>
                        <h3>{planDetails?.basicInfo?.planName}</h3>
                        {/* <p className="form-control">
                          {isMonthly ? "Monthly" : "Yearly"}
                        </p> */}
                        <select
                          class="form-control"
                          value={isMonthly}
                          onChange={() => setIsMonthly(!isMonthly)}
                        >
                          <option value={true}>Monthly</option>
                          <option value={false}>Yearly</option>
                        </select>
                      </aside>
                      <h3>
                        {isMonthly ? (
                          ""
                        ) : (
                          <h3 style={{ textDecoration: "line-through" }}>
                            ₹ {planDetails?.pricingInformation?.AnnualRate}
                          </h3>
                        )}
                        ₹{" "}
                        {isMonthly
                          ? planDetails?.pricingInformation?.MonthlyRate
                          : planDetails?.pricingInformation
                              ?.AnnualDiscountedRate}
                      </h3>
                    </div>
                    <div class="PaymentProtection">
                      <h5>Payment Protection</h5>
                      <h6>Book &amp; Pay on Bindle and get:</h6>
                      <ul>
                        <li style={{ display: "flex", alignItems: "center" }}>
                          <span>
                            {/* <img
                              src={require("../../assets/images/comprehensive.png")}
                            /> */}
                            <RiSecurePaymentFill
                              style={{ fontSize: "28px", color: "#fd701e" }}
                            />
                          </span>
                          Comprehensive Payment Protection
                        </li>
                        <li style={{ display: "flex", alignItems: "center" }}>
                          <span>
                            {/* <img
                              src={require("../../assets/images/emergency-phone.png")}
                            /> */}
                            <TbBrandBooking
                              style={{ fontSize: "28px", color: "#fd701e" }}
                            />
                          </span>
                          Emergency Booking Assistance
                        </li>
                        <li style={{ display: "flex", alignItems: "center" }}>
                          <span>
                            {/* <img
                              src={require("../../assets/images/customer-service-.png")}
                            /> */}
                            <RiCustomerServiceFill
                              style={{ fontSize: "28px", color: "#fd701e" }}
                            />
                          </span>
                          24hr Customer Service
                        </li>
                      </ul>
                    </div>
                    <div class="GstArea">
                      <aside>
                        <h4>GST (18 %)</h4>
                        <h3>
                          ₹{" "}
                          {((18 / 100) *
                            (isMonthly
                              ? planDetails?.pricingInformation?.MonthlyRate
                              : planDetails?.pricingInformation
                                  ?.AnnualDiscountedRate)).toFixed(0)}
                        </h3>
                      </aside>
                      {/* <a href="javascript:void(0);" class="AddGstDetails">
                        {" "}
                        + &nbsp; Add GST Details for Input Credit
                      </a> */}
                      <input
                        type="text"
                        className="AddGstDetails"
                        placeholder="Add GST Details for input credit"
                        value={gst}
                        onChange={handleGst}
                        onBlur={() => setGstError(false)}
                      />
                      {gstError && (
                        <p className="error-msg" style={{ color: "red" }}>
                          Incorrect GST (ex.-24AAACC1206D1ZM)
                        </p>
                      )}
                      <div class="TotalMonthly">
                        <h6>Total {isMonthly ? "Monthly" : "Yearly"} Fee</h6>
                        <h2>
                          {" "}
                          ₹{" "}
                          {((18 / 100) *
                            (isMonthly
                              ? planDetails?.pricingInformation?.MonthlyRate
                              : planDetails?.pricingInformation
                                  ?.AnnualDiscountedRate) +
                            parseInt(
                              isMonthly
                                ? planDetails?.pricingInformation?.MonthlyRate
                                : planDetails?.pricingInformation
                                    ?.AnnualDiscountedRate
                            )).toFixed(0)}
                        </h2>
                      </div>
                      <div class="px-3">
                        <a
                          onClick={() => handlePayment()}
                          class="PayNowBtn FolderPermissionId"
                          style={{ cursor: "pointer" }}
                          data-toggle="modal"
                          data-target="#Congratulations"
                          data-dismiss="modal"
                        >
                          Pay Now
                        </a>
                        <label class="CheckBox">
                          {" "}
                          I accept{" "}
                          <Link
                            to={"/terms-condition"}
                            style={{
                              textDecoration: "underline",
                              color: "#000",
                            }}
                          >
                            {" "}
                            terms and condition
                          </Link>
                          <input
                            type="checkbox"
                            checked={termsCheck}
                            onChange={() => setTermsCheck(!termsCheck)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </aside>
              </article>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UpgradePlan