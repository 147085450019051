import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import OwlCarousel from "react-owl-carousel2";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCityList,
  fetchPglisting,
} from "../reduxToolKit/slices/pgSlices/pgListingApiSlice";
import AutoPlace from "./CitySearch";
import { Link } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FaCar } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { GiWashingMachine } from "react-icons/gi";
import { SiAdguard } from "react-icons/si";
import { BsFillRouterFill } from "react-icons/bs";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import tvImage from "../assets/images/tv.png";
import singelBed from "../assets/images/singleBed.png";
import acImage from "../assets/images/acHeating.png";

import hotWaterImage from "../assets/images/hotWater.png";
import hanger from "../assets/images/hangers.png";
import wifi from "../assets/images/wifi.png";
import heating from "../assets/images/heating.png";
import Essentials from "../assets/images/essential.png";
import firstAid from "../assets/images/first-aid.png";
import freeParking from "../assets/images/free-parking.png";
import { BsGenderAmbiguous } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";

import { FaCreativeCommonsZero } from "react-icons/fa";
import { TbDeviceMobileOff } from "react-icons/tb";
import { MdVerifiedUser } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { fetchFlatlisting } from "../reduxToolKit/slices/flatSlice.js/flatListingSlice";
import axios from "axios";
import { url } from "../config/config";
import { isLoggedIn } from "../utils";
import { setSearchState } from "../reduxToolKit/slices/searchSlice";
const options = {
  nav: false,
  dots: true,
  rewind: true,
  autoplay: true,
  margin: 20,
  responsive: {
    0: {
      items: 1,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};

const initialState = {
  isActivePG: true,
  name: "",
  lat: "",
  long: "",
  gender: "",

  city: "",
  locality: "",
  street: "",
  address: "",
  location: {
    type: "point",
    coordinates: [],
  },
};

const initialFlatState = {
  name: "",
  lat: "",
  long: "",
  gender: "",
  preferredTenant: "",
  city: "",
  locality: "",
  street: "",
  address: "",
  location: {
    type: "point",
    coordinates: [],
  },
};

export const Home = () => {
  const searchState = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const [budgetRange, setBudgetRange] = useState([1000, 20000]);
  const [flatBudgetRange, setFlatBudgetRange] = useState([1000, 20000]);

  const cityState = useSelector((state) => state.cityReducer);
  const { homeData } = useSelector((state) => state.homeReducer);

  const [cityArray, setCityArray] = useState([]);
  const [searchAddress, setSearchAddress] = useState([]);

  const AmentiesArray = [
    {
      name: "TV",
      image: tvImage,
    },
    {
      name: "Hangers",
      image: hanger,
    },
    {
      name: "Air conditioning",
      image: acImage,
    },
    {
      name: "Wifi",
      image: wifi,
    },
    {
      name: "Heating",
      image: heating,
    },
    {
      name: "Essentials",
      image: Essentials,
    },
    {
      name: "First aid kit",
      image: firstAid,
    },
    {
      name: "Free parking",
      image: freeParking,
    },
    {
      name: "Hot water",
      image: hotWaterImage,
    },
  ];
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const [flatState, setFlatState] = useState(initialFlatState);

  const { address, city, gender, isActivePG } = iState;

  const { pgData } = useSelector((state) => state.pgListing);
  const { flatData } = useSelector((state) => state.flatListReducer);

  const expertiseArray = ["New Delhi", "Noida"];
  const [SuggestedProperty, setSuggestedProperty] = useState([]);
  const [PopularProperty, setPopularProperty] = useState([]);
  const [MostRatedProperty, setMostRatedProperty] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [selectCity, setSeclectCity] = useState();

  const fetchCityList = async (setCitylist) => {
    const response = await axios.get(`${url}user/getCities`, {});
    try {
      if (response.status) {
        setCitylist(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSuggestedPg = async (type, cityState) => {
    // console.log(cityState, "LOCATION");

    if (cityState?.currentLocation) {
      const response = await axios.get(
        `${url}user/getHomeListing?type=${type}&city=${
          cityState?.currentLocation?.split(",")?.[0]
        }`,
        {
          headers: {
            Authorization: isLoggedIn("pgUser"),
          },
        }
      );

      return response;
    }
  };

  const autoLocationHandler = () => {
    navigator.geolocation.getCurrentPosition((position, error) => {
      if (position) {
        updateState({
          ...iState,
          lat: position?.coords?.latitude,
          long: position?.coords?.longitude,
        });
        setFlatState({
          ...iState,
          lat: position?.coords?.latitude,
          long: position?.coords?.longitude,
        });
      }
    });
  };

  useEffect(() => {
    // dispatch(fetchPglisting({ long: iState.lang, lat: iState.lat }));
    autoLocationHandler();
    fetchCityList(setCitylist);
  }, []);

  const handleSearch = () => {
    if (!selectCity) {
      toast.error("Enter city for search");
    } else {
      const searchPayload = {
        iState: { ...iState, address: "" },
        cityArray: cityArray,
        addressArray: searchAddress, // This now reliably contains your locality data
        selectCity: selectCity,
        budgetRange: {
          min: budgetRange[0],
          max: budgetRange[1],
        },
      };
  
      dispatch(setSearchState(searchPayload));
  
      navigate("/search-result", { state: searchPayload });
    }
  };

  // const handleSearch = () => {
  //   if (!selectCity) {
  //     toast.error("Enter city for search");
  //   } else {
  //     dispatch(
  //       setSearchState({
  //         iState: { ...iState, address: "" },
  //         cityArray: cityArray,
  //         addressArray: searchAddress?.length == 0 ? searchAddress : [],
  //         selectCity: selectCity,
  //         budgetRange: {
  //           min: budgetRange[0],
  //           max: budgetRange[1],
  //         },
  //       })
  //     );

  //     navigate("/search-result", {
  //       state: {
  //         iState: { ...iState, address: "" },
  //         cityArray: cityArray,
  //         address: "",
  //         selectCity: selectCity,
  //         citylist: citylist,
  //         gender: iState?.gender,
  //         budgetRange: {
  //           min: budgetRange[0],
  //           max: budgetRange[1],
  //         },
  //         location: cityArray,
  //         addressArray: searchAddress?.length == 0 ? searchAddress : [],
  //       },
  //     });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleChangeForFlat = (e) => {
    const { name, value } = e.target;

    setFlatState({ ...flatState, [name]: value });
  };

  const handleFlatSearch = () => {
    if (!selectCity) {
      toast.error("Enter city for search");
    } else {
      dispatch(
        setSearchState({
          iState: { ...iState, address: "" },
          cityArray: cityArray,
          addressArray: searchAddress,
          selectCity: selectCity,
          budgetRange: {
            min: budgetRange[0],
            max: budgetRange[1],
          },
        })
      );

      navigate("/flat-search-result", {
        state: {
          citylist: citylist,
          location: cityArray,
          addressArray: searchAddress,
          selectCity: selectCity,
          preferredTenant: flatState.preferredTenant,
          budgetRange: {
            min: budgetRange[0],
            max: budgetRange[1],
          },
          type: "flat",
        },
      });
    }
  };

  useEffect(() => {
    setSuggestedProperty(homeData?.suggested);
    //   fetchSuggestedPg( "PG",cityState,
    //     iState.long ? iState.long : "77.026344",
    //     iState.lat ? iState.lat : "28.457523",).then((res) => setSuggestedProperty(res?.data?.data?.suggested?.[0]?.data));

    //   fetchSuggestedPg( "PG",cityState,
    //     iState.long ? iState.long : "77.026344",
    //     iState.lat ? iState.lat : "28.457523",)
    // .then((res) => setPopularProperty(res?.data?.data?.popular?.[0]?.data));

    //   fetchSuggestedPg(
    //      "PG",cityState,
    //     iState.long ? iState.long : "77.026344",
    //     iState.lat ? iState.lat : "28.457523",
    //   )
    // .then((res) => {
    //   console.log("MOST RES",res)
    //   setMostRatedProperty(res?.data?.data?.ratewise?.[0]?.data)});
  }, []);

  // const handleRemove = (i) => {
  //   let tempArray = [...cityArray];
  //   let addressArray = [...searchAddress];
  //   tempArray.splice(i, 1);
  //   addressArray.splice(i, 1);

  //   setSearchAddress(addressArray);
  // };

  const handleRemove = (i) => {
    let updatedCityArray = [...cityArray];
    let updatedAddressArray = [...searchAddress];
  
    updatedCityArray.splice(i, 1);
    updatedAddressArray.splice(i, 1);
  
    // Update both local states
    setCityArray(updatedCityArray);
    setSearchAddress(updatedAddressArray);
  
    // Also update the redux search state with the new arrays
    dispatch(
      setSearchState({
        ...searchState,
        cityArray: updatedCityArray,
        addressArray: updatedAddressArray,
      })
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     fetchPglisting({
  //       location: cityArray,
  //       // long: "77.3292032",
  //       // lat: "28.5868032",
  //     })
  //   );
  // }, [cityArray]);

  useEffect(() => {
    if (cityArray && cityArray.length > 0) {
      dispatch(fetchPglisting({ location: cityArray }));
    }
  }, [cityArray]);

  const handelNeed = (need) => {
    dispatch(
      setSearchState({
        iState: { ...iState, need, address: "" },
        cityArray: cityArray,
        addressArray: searchAddress,
        selectCity: cityState?.currentLocation?.split(",")?.[0],
        needs:need,
        budgetRange: {
          min: budgetRange[0],
          max: budgetRange[1],
        },
      })
    );
    navigate("/search-result", { state: { need: need, address: "" } });
  };
  const handleGender = (gender) => {
    dispatch(
      setSearchState({
        iState: { ...iState, gender, address: "" },
        cityArray: cityArray,
        addressArray: searchAddress,
        selectCity: cityState?.currentLocation?.split(",")?.[0],
        budgetRange: {
          min: budgetRange[0],
          max: budgetRange[1],
        },
      })
    );

    navigate("/search-result");
  };
  const handleViewAll = (type) => {
    dispatch(
      setSearchState({
        iState: { ...iState, viewType: type, viewall: true },
        cityArray: cityArray,
        addressArray: searchAddress,
        selectCity: cityState?.currentLocation?.split(",")?.[0],
        budgetRange: {
          min: budgetRange[0],
          max: budgetRange[1],
        },
      })
    );

    navigate("/search-result");
  };

  const handeleDetails = (e, item, scroll) => {
    // e.preventDefault();
    // e.stopPropagation();
    let item1 = {
      ...item,
      scroll: scroll,
    };
    navigate(`/pg-details?id=${item?._id}`, { state: item1 });
  };

  // console.log(homeData, "Header");
  return (
    <>
      <Header />
      <ToastContainer />

      <div class="HeroSection">
        <div class="container"></div>
      </div>

      <section>
        <div class="banner">
          <div class="banner-area">
            {/* <img src={require("../assets/images/banner.png")} alt="" /> */}
          </div>
          <figcaption>
            <h2>
              Find Your Perfect Accommodation
              <br />
              -Conveniently from Anywhere
            </h2>
            <h3>Rent Smarter, Hassle-Free</h3>
          </figcaption>

          <div class="banner-tab">
            <div class="CommonTabs">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#pg">
                    PG
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#flat">
                    Flat
                  </a>
                </li>
              </ul>
            </div>

            <div class="tab-content">
              <div
                class="tab-pane fade active show"
                id="pg"
                onClick={() =>
                  updateState((prev) => ({ ...prev, isActivePG: true }))
                }
              >
                <div class="Filter">
                  <div class="form-group">
                    <div class="gender customSelect">
                      {/* <span>
                        <img
                          src={require("../assets/images/location.png")}
                          alt=""
                        />
                      </span> */}
                      <span>
                        <IoLocationOutline fontSize={"22px"} color="#fd701e" />
                      </span>
                      <div className="newSelect">
                        <select
                          onChange={(e) => {
                            setSeclectCity(e.target.value);
                            setSearchAddress("");
                          }}
                          name="selectCity"
                          value={selectCity}
                        >
                          <option value={""}>Select city</option>
                          {citylist.length > 0 &&
                            citylist.map((elem, id) => {
                              return (
                                <option key={id} value={elem.name}>
                                  {elem.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    class="form-group CustomOne"
                    style={{ position: "relative" }}
                  >
                    <div class="loaction">
                      {/* <span>
                        <img
                          src={require("../assets/images/location.png")}
                          alt=""
                        />
                      </span> */}
                      <span>
                        <IoLocationOutline fontSize={"22px"} color="#fd701e" />
                      </span>
                      <AutoPlace
                        updateState={updateState}
                        iState={iState}
                        address={address}
                        cityArray={cityArray}
                        setCityArray={setCityArray}
                        searchAddress={searchAddress}
                        setSearchAddress={setSearchAddress}
                        selectCity={selectCity}
                      />{" "}
                    </div>
                    <div className="ExpertiseList">
                      <ul>
                        {searchAddress &&
                          searchAddress.map((item, i) => (
                            <li key={i}>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${i}`}>{item}</Tooltip>
                                }
                              >
                                <div style={{ cursor: "pointer" }}>
                                  {item?.split(",")[0].slice(0, 7)}...{" "}
                                  <span
                                    onClick={() => handleRemove(i)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    &times;
                                  </span>{" "}
                                </div>
                              </OverlayTrigger>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="gender customSelect">
                      {/* <span><img src={require("../assets/images/gender.png")} alt="" /></span> */}
                      <span>
                        <BsGenderAmbiguous fontSize={"22px"} color="#fd701e" />
                      </span>

                      <div className="newSelect">
                        <select
                          onChange={handleChange}
                          name="gender"
                          value={gender}
                        >
                          <option value={""}>Gender</option>
                          <option value={"Boys"}>Boys</option>
                          <option value={"Girls"}>Girls</option>
                          <option value={"Co-living"}>Co-Living</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group CustomTwo">
                    <div class="PriceSlideBox">
                      <aside>
                        {/* <span>
                          <img
                            src={require("../assets/images/rs.png")}
                            alt=""
                          />
                        </span> */}
                        <span>
                          <MdOutlineCurrencyRupee
                            fontSize={"22px"}
                            color="#fd701e"
                          />
                        </span>

                        <label>Rent Range</label>
                      </aside>
                      <Slider
                        allowCross={false}
                        range
                        min={500}
                        step={500}
                        value={budgetRange}
                        max={50000}
                        onChange={(e) => {
                          setBudgetRange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <a onClick={handleSearch} class="Button">
                      Search PG{" "}
                      {/* <img
                        src={require("../assets/images/left-arrow.png")}
                        alt=""
                      /> */}
                      <FaLongArrowAltRight
                        style={{ fontSize: "20px", color: "#fff" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="flat"
                onClick={() =>
                  updateState((prev) => ({ ...prev, isActivePG: false }))
                }
              >
                <div class="Filter">
                  <div class="form-group">
                    <div class="gender customSelect">
                      {/* <span>
                        <img
                          src={require("../assets/images/location.png")}
                          alt=""
                        />
                      </span> */}
                      <span>
                        <IoLocationOutline fontSize={"22px"} color="#fd701e" />
                      </span>
                      <div className="newSelect">
                        <select
                          onChange={(e) => {
                            setSeclectCity(e.target.value);
                            setSearchAddress("");
                          }}
                          name="selectCity"
                          value={selectCity}
                        >
                          <option value={""}>Select city</option>
                          {citylist.length > 0 &&
                            citylist.map((elem, id) => {
                              return (
                                <option key={id} value={elem.name}>
                                  {elem.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group CustomOne"
                    style={{ position: "relative" }}
                  >
                    <div class="loaction">
                      {/* <span>
                        <img
                          src={require("../assets/images/location.png")}
                          alt=""
                        />
                      </span> */}
                      <span>
                        <IoLocationOutline fontSize={"22px"} color="#fd701e" />
                      </span>
                      <AutoPlace
                        updateState={setFlatState}
                        iState={flatState}
                        address={address}
                        cityArray={cityArray}
                        setCityArray={setCityArray}
                        searchAddress={searchAddress}
                        setSearchAddress={setSearchAddress}
                        selectCity={selectCity}
                      />{" "}
                    </div>
                    <div className="ExpertiseList">
                      <ul>
                        {searchAddress &&
                          searchAddress.map((item, i) => (
                            <li key={i}>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${i}`}>{item}</Tooltip>
                                }
                              >
                                <div style={{ cursor: "pointer" }}>
                                  {item?.split(",")[0].slice(0, 7)}...{" "}
                                  <span
                                    onClick={() => handleRemove(i)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    &times;
                                  </span>{" "}
                                </div>
                              </OverlayTrigger>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="gender customSelect">
                      {/* <span>
                        <img
                          src={require("../assets/images/gender.png")}
                          alt=""
                        />
                      </span> */}
                      <span>
                        <BsGenderAmbiguous fontSize={"22px"} color="#fd701e" />
                      </span>
                      <div className="newSelect">
                        <select
                          onChange={handleChangeForFlat}
                          name="preferredTenant"
                          value={flatState.preferredTenant}
                        >
                          <option value={""}>Tenant Type</option>
                          <option value={"Anyone"}>Anyone</option>
                          <option value={"Family"}>Family</option>
                          <option value={"Bachelor Male"}>Bachelor Male</option>
                          <option value={"Bachelor Femal"}>
                            Bachelor Female
                          </option>
                          <option value={"Company"}>Company</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group CustomTwo">
                    <div class="PriceSlideBox">
                      <aside>
                        {/* <span>
                          <img
                            src={require("../assets/images/rs.png")}
                            alt=""
                          />
                        </span> */}
                        <span>
                          <MdOutlineCurrencyRupee
                            fontSize={"22px"}
                            color="#fd701e"
                          />
                        </span>
                        <label>Rent Range</label>
                      </aside>
                      <Slider
                        allowCross={false}
                        range
                        min={500}
                        step={500}
                        value={budgetRange}
                        max={50000}
                        onChange={(e) => {
                          setBudgetRange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <a onClick={handleFlatSearch} class="Button">
                      Search Flat{" "}
                      {/* <img
                        src={require("../assets/images/left-arrow.png")}
                        alt=""
                      /> */}
                      <FaLongArrowAltRight
                        style={{ fontSize: "20px", color: "#fff" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="PayingGuest">
          <div class="section-title">
            <p>Paying Guest</p>
            <h3>Find PGs based on your need</h3>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              {/* <Link to="/search-result"> */}
              <div class="PayingGuestBox" onClick={() => handleGender("Boys")}>
                <figure>
                  <img
                    src={require("../assets/images/guest-room-3.png")}
                    alt="img"
                  />
                  <figcaption>
                    <h5>PGs For Boys</h5>
                    <i class="fa-solid fa-arrow-right"></i>
                  </figcaption>
                </figure>
              </div>
              {/* </Link> */}
            </div>
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              onClick={() => handleGender("Girls")}
            >
              {/* <Link to="/search-result"> */}
              <div class="PayingGuestBox">
                <figure>
                  <img
                    src={require("../assets/images/guest-room-2.png")}
                    alt="img"
                  />
                  <figcaption>
                    <h5>PGs For Girls</h5>
                    <i class="fa-solid fa-arrow-right"></i>
                  </figcaption>
                </figure>
              </div>
              {/* </Link> */}
            </div>
            <div
              class="col-lg-4 col-md-6 col-sm-6 col-12"
              onClick={() => handleGender("Co-living")}
            >
              {/* <Link to="/search-result"> */}
              <div class="PayingGuestBox">
                <figure>
                  <img
                    src={require("../assets/images/guest-room-1.png")}
                    alt="img"
                  />
                  <figcaption>
                    <h5>PGs For Co-Living</h5>
                    <i class="fa-solid fa-arrow-right"></i>
                  </figcaption>
                </figure>
              </div>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="download">
          <figure>
            <img
              class="download-image"
              src={require("../assets/images/banner-pg.png")}
              alt=""
            />
          </figure>

          <span>
            <h3 style={{ color: "#000" }}>
              Download the mobile application for bonus <br /> coupons and
              travel codes
            </h3>
            <img src={require("../assets/images/android.png")} alt="" />
            <img src={require("../assets/images/ios.png")} alt="" />
          </span>
        </div>
      </section>

      <section>
        <div class="TestimonialArea">
          <div class="section-title">
            <p>Testimonials</p>
            <h3>Unveiling Our Happy Customers' Stories</h3>
          </div>
          <OwlCarousel options={options}>
            <div class="item">
              <div class="TestimonialBox">
                <figure>
                  <img
                    src={require("../assets/images/guest-room-1.png")}
                    alt="img"
                  />
                  <figcaption>
                    <h5>
                      <span>D</span>Neha Kumari
                    </h5>
                    {/* <p>“It’s Unbelievable, I Really Like it”</p> */}
                    <p>
                      "If you're looking for PG or a flat, PgDekho is the way to
                      go. The search was fast, and I found a great place with
                      ease. Fantastic!"
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="item">
              <div class="TestimonialBox">
                <figure>
                  <img
                    src={require("../assets/images/guest-room-2.png")}
                    alt="img"
                  />
                  <figcaption>
                    <h5>
                      <span>A</span>Vivek Mishra
                    </h5>
                    {/* <p>“It’s Unbelievable, I Really Like it”</p> */}
                    <p>
                      "Finding my PG was a breeze with PGDEKHO. The site was
                      easy to use, and I found a great place in no time. Highly
                      recommend!"
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="item">
              <div class="TestimonialBox">
                <figure>
                  <img
                    src={require("../assets/images/guest-room-3.png")}
                    alt="img"
                  />
                  <figcaption>
                    <h5>
                      <span>B</span>Suraj Verma
                    </h5>
                    {/* <p>“It’s Unbelievable, I Really Like it”</p> */}
                    <p>
                      "I was amazed at how simple it was to search for a flat on
                      Pgdekho. The filters helped me find exactly what I needed
                      quickly. Great service!"
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="item">
              <div class="TestimonialBox">
                <figure>
                  <img
                    src={require("../assets/images/guest-room-2.png")}
                    alt="img"
                  />
                  <figcaption>
                    <h5>
                      <span>C</span>Sachin Gupta
                    </h5>
                    <p>“It’s Unbelievable, I Really Like it”</p>
                    <p>
                      "Thanks to Pgdekho, I found a perfect PG room in just a
                      few clicks. The listings were clear and detailed. So glad
                      I found this site!"
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section>
        <div class="WhyChooseUsArea">
          <div class="section-title">
            <p>Our Services</p>
            <h3>Why Choose Us</h3>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
              <div class="WhyChooseBox">
                <span class="Icon">
                  {/* <img src={require("../assets/images/money-send.png")} /> */}
                  <FaCreativeCommonsZero
                    style={{ fontSize: "32px", color: "#fff" }}
                  />
                </span>
                <h5>ZERO BROKERAGE</h5>
                <p>
                  Enjoy Free Access to Our Platform. Browse Listings and Contact
                  Property Owners Directly Without Any Fee.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="WhyChooseBox">
                <span class="Icon">
                  {/* <img src={require("../assets/images/money-send.png")} /> */}
                  <MdVerifiedUser style={{ fontSize: "32px", color: "#fff" }} />
                </span>
                <h5>SEARCH VERIFIED PROPERTIES</h5>
                <p>
                  Discover Your Ideal Place at Your Own Pace. Experience
                  Immersive Photos and Extensive Listings, All Verified for Your
                  Peace of Mind.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12">
              <div class="WhyChooseBox">
                <span class="Icon">
                  {/* <img src={require("../assets/images/money-send.png")} /> */}
                  <TbDeviceMobileOff
                    style={{ fontSize: "32px", color: "#fff" }}
                  />
                </span>
                <h5>NO UNWANTED CALLS</h5>
                <p>
                  Enjoy a Seamless Search Experience with Complete Control Over
                  Who Contacts You and When.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="collection">
          <div class="section-title">
            <p>Explore Our</p>
            <h3>Handpicked Collections</h3>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12" style={{ cursor: "pointer" }}>
              <a onClick={() => handelNeed("Budgets")}>
                <figure>
                  <img
                    src={require("../assets/images/collection-1.png")}
                    alt=""
                  />
                </figure>
                <figcaption>
                  <h2>Budget Friendly</h2>
                </figcaption>
              </a>
            </div>
            <div class="col-lg-6 col-md-12" style={{ cursor: "pointer" }}>
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <a onClick={() => handelNeed("Wi-fi")}>
                    <figure>
                      <img
                        src={require("../assets/images/collection-2.png")}
                        alt=""
                      />
                    </figure>
                    <figcaption>
                      <h2>Wifi Included</h2>
                    </figcaption>
                  </a>
                </div>
                <div
                  class="col-lg-6 col-md-6 col-sm-12"
                  style={{ cursor: "pointer" }}
                >
                  <a onClick={() => handelNeed("Single Bed")}>
                    <figure>
                      <img
                        src={require("../assets/images/collection-3.png")}
                        alt=""
                      />
                    </figure>
                  </a>
                  <figcaption>
                    <h2>Single Bed</h2>
                  </figcaption>
                </div>
                <div class="col-lg-12 col-md-12" style={{ cursor: "pointer" }}>
                  <a onClick={() => handelNeed("Students")}>
                    <figure>
                      <img
                        src={require("../assets/images/collection-4.png")}
                        alt=""
                      />
                    </figure>
                  </a>
                  <figcaption>
                    <h2>For Students</h2>
                  </figcaption>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="CommonPgArea PinkBg">
          <div className="TitleHeading">
            <h2 class="Title">Popular PGs </h2>
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => handleViewAll("popularityWise")}
            >
              View All
            </h6>
          </div>
          <div class="row">
            {homeData?.popular?.[0]?.data &&
              Array.isArray(homeData?.popular?.[0]?.data) &&
              homeData?.popular?.[0]?.data?.slice(0, 4)?.map((item) => {
                let minAmount = Math.min(
                  ...item?.pgRooms.map((item) => item.monthlyRent)
                );
                let banner = item?.propertyMedia?.filter((ele) => ele?.default);

                return (
                  <div class="col-lg-3 col-md-6">
                    <div onClick={(e) => handeleDetails(e, item, false)}>
                      <div
                        class="ViewContactBox"
                        style={{ minHeight: "520px" }}
                      >
                        <figure>
                          <img
                            src={
                              banner?.length > 0
                                ? banner?.[0]?.mediaUrl
                                : require("../assets/images/pg-4.png")
                            }
                          />
                        </figure>
                        <figcaption>
                          <h3>{item?.name}</h3>
                          <p>
                            <i class="fa-solid fa-location-dot"></i>{" "}
                            {item?.address?.slice(0, 20)}...
                          </p>
                          <div
                            class="RoomAmenities"
                            style={{ minHeight: "160px" }}
                          >
                            <h4>Rs. {minAmount} onwards</h4>
                            <ul class="PreferredButton">
                              <li>For {item?.availableFor}</li>
                              <li>{item?.preferredGuest} preferred</li>
                            </ul>
                            <ul>
                              <li>
                                <span>
                                  <BsFillRouterFill
                                    style={{
                                      fontSize: "22px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <FaCar
                                    style={{
                                      fontSize: "22px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <FaLock
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <GiWashingMachine
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <SiAdguard
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <small>+ 17 More</small>
                              </li>
                            </ul>
                          </div>
                          <Link to="/pg-details" state={item}>
                            Contact Owner
                          </Link>
                        </figcaption>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section>
        <div class="CommonPgArea">
          <div className="TitleHeading">
            <h2 class="Title">Suggested Properties </h2>
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => handleViewAll("suggested")}
            >
              View All
            </h6>
          </div>
          <div class="row">
            {homeData?.suggested?.[0]?.data &&
              Array.isArray(homeData?.suggested?.[0]?.data) &&
              homeData?.suggested?.[0]?.data?.slice(0, 4)?.map((item) => {
                let minAmount = Math.min(
                  ...item?.pgRooms.map((item) => item.monthlyRent)
                );
                let banner = item?.propertyMedia?.filter((ele) => ele?.default);

                return (
                  <div class="col-lg-3 col-md-6 col-sm-12">
                    <div onClick={(e) => handeleDetails(e, item, false)}>
                      <div
                        class="ViewContactBox"
                        style={{ minHeight: "520px" }}
                      >
                        <figure>
                          <img
                            src={
                              banner?.length > 0
                                ? banner?.[0]?.mediaUrl
                                : require("../assets/images/pg-4.png")
                            }
                          />
                        </figure>
                        <figcaption>
                          <h3>{item?.name}</h3>
                          <p>
                            <i class="fa-solid fa-location-dot"></i>{" "}
                            {item?.address?.slice(0, 20)}...
                          </p>
                          <div
                            class="RoomAmenities"
                            style={{ minHeight: "160px" }}
                          >
                            <h4>Rs. {minAmount} onwards</h4>
                            <ul class="PreferredButton">
                              <li>For {item?.availableFor}</li>
                              <li>{item?.preferredGuest} preferred</li>
                            </ul>
                            <ul>
                              <li>
                                <span>
                                  <BsFillRouterFill
                                    style={{
                                      fontSize: "22px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <FaCar
                                    style={{
                                      fontSize: "22px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <FaLock
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <GiWashingMachine
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <SiAdguard
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <small>+ 17 More</small>
                              </li>
                            </ul>
                          </div>
                          <Link to="/pg-details" state={item}>
                            Contact Owner
                          </Link>
                        </figcaption>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section>
        <div class="CommonPgArea PinkBg">
          <div className="TitleHeading">
            <h2 class="Title">Most Rated Property </h2>
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => handleViewAll("ratingwise")}
            >
              View All
            </h6>
          </div>
          <div class="row">
            {homeData?.ratewise?.[0]?.data &&
              Array.isArray(homeData?.ratewise?.[0]?.data) &&
              homeData?.ratewise?.[0]?.data?.slice(0, 4)?.map((item) => {
                let minAmount = Math.min(
                  ...item?.pgRooms.map((item) => item.monthlyRent)
                );
                let banner = item?.propertyMedia?.filter((ele) => ele?.default);

                return (
                  <div class="col-lg-3 col-md-6">
                    <div onClick={(e) => handeleDetails(e, item, false)}>
                      <div
                        class="ViewContactBox"
                        style={{ minHeight: "520px" }}
                      >
                        <figure>
                          <img
                            src={
                              banner?.length > 0
                                ? banner?.[0]?.mediaUrl
                                : require("../assets/images/pg-4.png")
                            }
                          />
                        </figure>
                        <figcaption>
                          <h3>{item?.name}</h3>
                          <p>
                            <i class="fa-solid fa-location-dot"></i>{" "}
                            {item?.address?.slice(0, 20)}...
                          </p>
                          <div
                            class="RoomAmenities"
                            style={{ minHeight: "160px" }}
                          >
                            <h4>Rs. {minAmount} onwards</h4>
                            <ul class="PreferredButton">
                              <li>For {item?.availableFor}</li>
                              <li>{item?.preferredGuest} preferred</li>
                            </ul>
                            <ul>
                              <li>
                                <span>
                                  <BsFillRouterFill
                                    style={{
                                      fontSize: "22px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <FaCar
                                    style={{
                                      fontSize: "22px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <FaLock
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <GiWashingMachine
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <span>
                                  <SiAdguard
                                    style={{
                                      fontSize: "20px",
                                      color: "#FD701E",
                                    }}
                                  />
                                </span>
                              </li>
                              <li>
                                <small>+ 17 More</small>
                              </li>
                            </ul>
                          </div>
                          <Link onClick={(e) => handeleDetails(e, item, false)}>
                            Contact Owner
                          </Link>
                        </figcaption>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <Footer />

      <div class="ModalBox">
        <div class="modal fade" id="VideoModal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="VideoBox">
                <button type="button" class="CloseModal" data-dismiss="modal">
                  &times;
                </button>

                <iframe
                  src="https://www.youtube.com/embed/7Wc0kaJrOks"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
