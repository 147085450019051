import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setSearchState } from "../reduxToolKit/slices/searchSlice"; // adjust the path as needed
import { toast } from "react-toastify";

const AutoPlace = ({
  updateState,
  iState,
  address,
  cityArray,
  setCityArray,
  searchAddress,
  setSearchAddress,
  selectCity,
}) => {
  // console.log( 'Auto place payload ==>' ,
  //   // updateState,
  //   // iState,
  //   // address,
  //   // cityArray,
  //   // setCityArray,
  //   // searchAddress,
  //   // setSearchAddress,
  //   // selectCity,
  // )
  const [tempState, setTempState] = useState("");
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.searchReducer);

  const handleChange = (address) => {
    updateState((prev) => ({ ...prev, address }));
    setTempState(address);
  };

  // const handleSelect = (address) => {
  //   // Only add the location if it contains selectCity and it isn't already selected
  //   if (address.includes(selectCity) && !searchAddress.includes(address)) {
  //     geocodeByAddress(address)
  //       .then((results) => {
  //         const tempAddress = extractAddressComponents(results[0].address_components);
  //         return { tempAddress, address, results };
  //       })
  //       .then((latLng) => {
  //         getLatLng(latLng.results[0]).then((res) => {
  //           updateState((prev) => ({ ...prev, lat: res.lat, long: res.lng }));
  //         });
  //         let updatedLocality = "";
  //         if (latLng.tempAddress.locality) {
  //           updatedLocality = latLng.tempAddress.locality;
  //         } else if (latLng.tempAddress.city) {
  //           updatedLocality = latLng.tempAddress.city;
  //         } else {
  //           updatedLocality = latLng.tempAddress.state;
  //         }
  //         // Dispatch the new locality to redux (appending to the array)
  //         console.log('These are localities searchStare before &&&&======> ', searchState.addressArray)
  //         dispatch(
  //           setSearchState({
  //             ...searchState,
  //             addressArray: [...(searchState.addressArray || []), updatedLocality],
  //           })
  //         );
  //         console.log('These are localities searchState after &&&&======> ', searchState.addressArray)
  //         console.log('These are localities after update &&&&======> ', updatedLocality)
  //         // Update the local state arrays as well
  //         setCityArray((prev) => [...prev, updatedLocality]);
  //         setSearchAddress((prev) => [...prev, updatedLocality]);
  //         // Clear the input after selection
  //         setTempState("");
  //       })
  //       .catch((error) => console.error("Error", error));
  //   } else {
  //     toast.error(`Please select another city in ${selectCity}`);
  //   }
  // };

  const handleSelect = (address) => {
    if (address.includes(selectCity) && !searchAddress.includes(address)) {
      geocodeByAddress(address)
        .then((results) => {
          const tempAddress = extractAddressComponents(
            results[0].address_components
          );
          return { tempAddress, address, results };
        })
        .then((latLng) => {
          getLatLng(latLng.results[0]).then((res) => {
            updateState((prev) => ({ ...prev, lat: res.lat, long: res.lng }));
          });
          let updatedLocality = "";
          if (latLng.tempAddress.locality) {
            updatedLocality = latLng.tempAddress.locality;
          } else if (latLng.tempAddress.city) {
            updatedLocality = latLng.tempAddress.city;
          } else {
            updatedLocality = latLng.tempAddress.state;
          }
          // Compute new arrays here
          const newCityArray = [...cityArray, updatedLocality];
          const newSearchAddress = [...searchAddress, updatedLocality];
          // Update local state
          setCityArray(newCityArray);
          setSearchAddress(newSearchAddress);
          // Dispatch the updated arrays to Redux immediately
          // dispatch(
          //   setSearchState({
          //     ...searchState,
          //     addressArray: newSearchAddress,
          //   })
          // );
          dispatch(
            setSearchState((prevState) => ({
              ...prevState,
              addressArray: [
                ...(prevState.addressArray || []),
                updatedLocality,
              ],
            }))
          );

          // setTempState("");
          // Clear the input: update both the local tempState and the address in iState
          setTempState("");
          updateState((prev) => ({ ...prev, address: "" }));
        })
        .catch((error) => console.error("Error", error));
    } else {
      toast.error(`Please select another city in ${selectCity}`);
    }
  };

  // (Optional) Fetch address for current coordinates – keep if needed.
  useEffect(() => {
    fetchAddress(iState.lat, iState.long);
    // Optionally, if you want to initialize the input from the address prop, you can do:
    setTempState(address);
  }, [address, iState.lat, iState.long]);

  const fetchAddress = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=YOUR_API_KEY`
    )
      .then((response) => response.json())
      .then((data) => {
        // You can use the fetched data if needed
        // let tempFormattedAddress = data?.results?.[0]?.place_id ?? "";
      });
  };

  function extractAddressComponents(data) {
    let city = null;
    let locality = null;
    let sublocality = null;
    let state = null;
    let country = null;

    data.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.long_name;
      } else if (component.types.includes("sublocality")) {
        if (component.types.includes("sublocality_level_1")) {
          locality = component.long_name;
        }
        if (component.types.includes("sublocality_level_2")) {
          sublocality = component.long_name;
        }
      }
    });
    return { city, locality, sublocality, state, country };
  }

  // console.log( 'City array in AutoPlace ==>' ,cityArray)

  return (
    <PlacesAutocomplete
      value={tempState}
      onChange={handleChange}
      onSelect={handleSelect}
      // Optionally, you can configure searchOptions if needed.
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder:
                searchAddress?.length > 0
                  ? "Add more..."
                  : "Select location or locality up to 3 keywords",
              className: "location-search-input form-control customInput",
              disabled: searchAddress?.length >= 3,
              style: {
                paddingLeft:
                  searchAddress?.length === 1
                    ? "140px"
                    : searchAddress?.length === 2
                    ? "235px"
                    : "30px",
              },
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions
              .filter((elem) => elem.description.includes(selectCity))
              .map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <p>{suggestion?.description}</p>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutoPlace;
