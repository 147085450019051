import React, { lazy, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const AutoPlace = ({ updateState, iState, address}) => {
  const [tempState, setTempState] = useState("");

const [longitude, setLongitue] = useState('');
const [latitude, setLatitude] =useState('');
  const handleChange = (address) => {
    // this.setState({ address });

    if(address){
      updateState((pre)=> ({...pre,address}))


    }
    setTempState(address);
  };

  const handleSelect = (address) => {
    setTempState("");





    geocodeByAddress(address)
      .then((results) => {

        let tempAddress = extractAddressComponents(results?.[0]?.address_components)

        // setTempState(address);
        // getLatLng(results[0]);
        return {tempAddress,address,results}
      }
      ).then((latLng) =>{
getLatLng(latLng.results[0]).then((res)=> { 

  updateState((prev)=>({ ...prev, lat:res.lat, long:res.lng
  }))

});
      updateState((prev)=>({ ...prev, address: latLng.address,  city: latLng.tempAddress.city,
        locality: latLng.tempAddress.locality,
        street: latLng.tempAddress.sublocality
      }))

  }
      )
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    fetchAddress(iState.lat, iState.long)

    setTempState(address);
  }, [address]);


  
  const fetchAddress = (lat, lng) => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key='AIzaSyB0oVP9ASJypwf7BLW86zTwqqM2iSI0DuE'`)
    // fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key='AIzaSyBDI26u2BD-qaKwIavB-t_Fka6nHbVK1CA'`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        let tempFormattedAddress = data?.results?.[0]?.place_id ?? "";
        // updateState((prev) => ({...prev,  pinCode: tempFormattedAddress }))

      })
  }

  function extractAddressComponents(data) {
    let city = null;
    let locality = null;
    let sublocality = null;
    let state = null;
    let country = null;

    data.forEach(component => {
        if (component.types.includes("locality")) {
            city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
        } else if (component.types.includes("country")) {
            country = component.long_name;
        } else if (component.types.includes("sublocality")) {
            if (component.types.includes("sublocality_level_1")) {
                locality = component.long_name;
            }
            if (component.types.includes("sublocality_level_2")) {
                sublocality = component.long_name;
            }
        }
    });
    
    return { city, locality, sublocality, state, country };
}

  return (
    <PlacesAutocomplete
      value={tempState}
      onChange={handleChange}
      onSelect={handleSelect}
      

    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input

            {...getInputProps({
              placeholder: "Enter Location ",
              className: "location-search-input form-control",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutoPlace;
