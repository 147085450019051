// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   verified: true,
//   name: "",
//   lat: "",
//   long: "",
//   gender: "",
//   roomType: "",
//   food: "",
//   postBy: "",
//   parking: "",
//   sort: "",

//   city: "",
//   locality: "",
//   street: "",
//   address: "",
//   availability: "",
//   roomType: [],
//   location: {
//     type: "point",
//     coordinates: [],
//   },
//   addressArray: []
// };

// const searchSlice = createSlice({
//   name: 'search',
//   initialState,
//   reducers: {
//     setSearchState: (state, action) => {
//       return { ...state, ...action.payload };
//     }
//   }
// });

// export const { setSearchState } = searchSlice.actions;
// export default searchSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  verified: true,
  name: "",
  lat: "",
  long: "",
  gender: "",
  roomType: "",
  food: "",
  postBy: "",
  parking: "",
  needs:"",
  sort: "",
  city: "",
  locality: "",
  street: "",
  address: "",
  availability: "",
  roomType: [],
  location: {
    type: "point",
    coordinates: [],
  },
  addressArray: []
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchState: (state, action) => {
      if (typeof action.payload === 'function') {
        // Call the function with the current state to get the new state.
        console.log('This is search state ====>',state)
        return action.payload(state);
      } else {
        return { ...state, ...action.payload };
      }
    }
  }
});

export const { setSearchState } = searchSlice.actions;
export default searchSlice.reducer;
