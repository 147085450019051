module.exports = {
    // url : "https://pgdekhoindia.com:4500/api/v1/",
    // socket_url:'http://pgdekhoindia.com:8083',
    url : "https://pgdekho.com:4500/api/v1/",
    socket_url:'http://pgdekho.com:8083',

    // url : "http://pgdekhoindia.com:8083/api/v1/",
    // socket_url:'http://pgdekhoindia.com:8083',

    // url : "http://localhost:8083/api/v1/",
    // socket_url:'http://localhost:8083',

    s3Keys : {
        bucketName:"pgdekhoprod",
        dirName: "pgDekhoWeb",
        region: "ap-south-1",
        accessKeyId:"AKIA4T4OCC6BYGHTU35E",
        secretAccessKey:"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",
        }
}