import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { raiseTicketApi } from "../../reduxToolKit/slices/supportSlice/raiseTicketSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { callRequestApi } from "../../reduxToolKit/slices/supportSlice/callRequestSlice";
import { fetchProfile } from "../../reduxToolKit/slices/authSlice.js/getProfileSlice";
import { Modal } from "react-bootstrap";

const HelpAndSupport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = JSON.parse(window.localStorage.getItem("pgUser"));
  const initialState = {
    userType: "",
    issues: "",
    description: "",
    phone: userDetails?.phone,
    errorMsg: {},
  };

  const [iState, updateState] = useState(initialState);
  const [userModal, setUSerModal] = useState(false);

  const { userType, issues, description, phone, errorMsg } = iState;

  const handleChange = (e) => {
    const { name, value } = e.target;

    updateState({ ...iState, [name]: value });
  };

  const handleValidation = () => {
    let flag = true;
    let errorMsg = {};

    if (!userType) {
      flag = false;
      errorMsg.userType = " User type field is required";
    }

    if (!phone) {
      flag = false;
      errorMsg.phone = " Phone field is required";
    } else if (phone < 10) {
      flag = false;
      errorMsg.phoneValid = " Phone number is not valid";
    }

    if (!issues) {
      flag = false;
      errorMsg.issues = "Isssue  field is required";
    }

    if (!description) {
      flag = false;
      errorMsg.description = "Description  field is required";
    }

    updateState({ ...iState, errorMsg });

    return flag;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    let data = {
      userType: userType,
      issues: issues,
      description: description,
      phone: phone,
    };

    if (formIsValid) {
      dispatch(raiseTicketApi(data)).then((res) => {
        if (res?.payload?.success) {
          toast.success("Raise ticket successfully !");
          navigate("/my-ticket-support");
        } else {
          toast.error("Something went wrong !");

          // navigate("/login-verify", { state: iState });
        }
      });
    }
  };

  const handleCallRequest = (e) => {
    e.preventDefault();
    let errorMsg = {};
    if (userType == "") {
      errorMsg.userType = " User type field is required";
      updateState({ ...iState, errorMsg });
      toast.warning("UserType field is required for callback request");
    } else {
      let data = {
        userType: userType,
        phone: phone,
      };
      dispatch(callRequestApi(data)).then((res) => {
        if (res?.payload?.success) {
          toast.success("Requested successfully !");
          setUSerModal(false);
        } else {
          toast.error("Something went wrong !");

          // navigate("/login-verify", { state: iState });
        }
      });
    }
  };

  const handleViewTkt = (e) => {
    e.preventDefault();
    let errorMsg = {};
    if (userType == "") {
      errorMsg.userType = " User type field is required";
      updateState({ ...iState, errorMsg });
      toast.warning("UserType field is required for callback request");
    } else {
      navigate("/my-ticket-support", { state: { userType: userType } });
    }
  };

  const handleClose = () => {
    setUSerModal(!userModal);
  };

  return (
    <>
      <ToastContainer />

      <Header />
      <div class="help">
        <div class="help-area">
          <aside>
            <h3>Raise an issue</h3>
            <div style={{ cursor: "pointer" }}>
              <a
                onClick={() => setUSerModal(true)}
                style={{ marginRight: "8px" }}
              >
                View Tickets
              </a>
              <a onClick={() => setUSerModal(true)}>Request A call Back</a>
            </div>
          </aside>

          <article>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>You are a *</label>
                  {/* <select
                    class="form-control"
                    onChange={handleChange}
                    name="userType"
                  >
                    <option value={""}>Select</option>

                    <option value={"Owner"}>Owner</option>
                    <option value={"Agent"}>Agent</option>
                    <option value={"User"}>
                      Someone looking for Property
                    </option>
                  </select> */}

                  <select
                    className="form-control"
                    onChange={handleChange}
                    name="userType"
                  >
                    <option value={""}>Select</option>
                    <option value={"Owner"}>PG Owner</option>
                    <option value={"User"}>Tenant</option>
                  </select>

                  {/* {errorMsg.userType && !userType && (
                    <p className="error-msg" style={{ color: "red" }}>
                      {errorMsg.userType}
                    </p>
                  )} */}
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label>Select issue you are facing from list*</label>
                  {/* <select
                    class="form-control"
                    onChange={handleChange}
                    name="issues"
                  >
                    <option value={""}>Select</option>

                    <option value={"Fake Property"}>Fake Property</option>
                    <option value={"Difficulty in posting property"}>
                      Difficulty in posting property
                    </option>
                  </select> */}

                  {userType === "" ? (
                    <select className="form-control" disabled>
                      <option value="">Select user type first</option>
                    </select>
                  ) : userType === "Owner" ? (
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="issues"
                    >
                      <option value={""}>Select</option>
                      <option value={"Unable to list my PG/property"}>
                        Unable to list my PG/property
                      </option>
                      <option value={"My property listing is not visible"}>
                        My property listing is not visible
                      </option>
                      <option value={"Issues with pricing and rental details"}>
                        Issues with pricing and rental details
                      </option>
                      <option value={"Unable to edit or update my profile"}>
                        Unable to edit or update my profile
                      </option>
                      <option value={"Not receiving tenant inquiries"}>
                        Not receiving tenant inquiries
                      </option>
                      <option value={"Payment issues for premium listing"}>
                        Payment issues for premium listing
                      </option>
                      <option value={"Need help with tenant verification"}>
                        Need help with tenant verification
                      </option>
                      <option value={"Other issues"}>Other issues</option>
                    </select>
                  ) : userType === "User" ? (
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="issues"
                    >
                      <option value={""}>Select</option>
                      <option value={"Unable to find suitable PG options"}>
                        Unable to find suitable PG options
                      </option>
                      <option value={"Contacted PG owner but no response"}>
                        Contacted PG owner but no response
                      </option>
                      <option value={"Issues with booking a PG"}>
                        Issues with booking a PG
                      </option>
                      <option value={"Rent payment issues"}>
                        Rent payment issues
                      </option>
                      <option value={"Need help with refund/cancellation"}>
                        Need help with refund/cancellation
                      </option>
                      <option value={"Verification issues for booking"}>
                        Verification issues for booking
                      </option>
                      <option value={"Other issues"}>Other issues</option>
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="issues"
                    >
                      <option value={""}>Select</option>
                      <option value={"Fake Property"}>Fake Property</option>
                      <option value={"Difficulty in posting property"}>
                        Difficulty in posting property
                      </option>
                    </select>
                  )}

                  {errorMsg.issues && !issues && (
                    <p className="error-msg" style={{ color: "red" }}>
                      {errorMsg.issues}
                    </p>
                  )}
                </div>
              </div>

              <div class="col-sm-12">
                <div class="form-group">
                  <label></label>
                  <CKEditor
                    id="editor1"
                    className="form-control"
                    editor={ClassicEditor}
                    //data={overview} // add in state and use state key
                    data={description} // add in state and use state key
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      updateState({ ...iState, description: data });
                    }}
                  />
                  {errorMsg.description && !description && (
                    <p className="error-msg" style={{ color: "red" }}>
                      {errorMsg.description}
                    </p>
                  )}{" "}
                </div>
              </div>

              <div class="col-sm-12">
                <a onClick={handleSubmit} class="button">
                  Raise Ticket
                </a>
              </div>
            </div>
          </article>
        </div>
      </div>
      <Footer />
      <Modal show={userModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>You are a </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select class="form-control" onChange={handleChange} name="userType">
            <option value={""}>Select</option>

            <option value={"Owner"}>Owner</option>
            <option value={"Agent"}>Agent</option>
            <option value={"User"}>Someone looking for Property</option>
          </select>
          <div class="help-area" style={{ border: "none" }}>
            <aside>
              <div style={{ cursor: "pointer" }}>
                <a onClick={handleViewTkt} style={{ marginRight: "8px" }}>
                  View Tickets
                </a>
                <a onClick={handleCallRequest}>Request A call Back</a>
              </div>
            </aside>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HelpAndSupport;
