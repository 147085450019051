import React, { useEffect } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfile } from '../../reduxToolKit/slices/authSlice.js/getProfileSlice';
import { Link } from 'react-router-dom';

const MyProfile = () => {

    const {profileData} = useSelector((state)=>state.profileReducer)

    const dispatch = useDispatch()

    useEffect(()=>{

        dispatch(fetchProfile())

    },[])


  return (
<>
<Header />
<section>
        <div class="my-profile-profile">
            <div class="profile-img">
                <img src={require("../../assets/images/bg-profile.png" )} alt="" />
                {/* <img src={require("../../assets/images/logo.png" )} alt="" /> */}
            </div>
            <div class="profile-area">
                <aside>
                    <figure>
                        {/* <img src={profileData?.data?.profileImage?profileData?.data?.profileImage:require("../../assets/images/avatar.png")} alt="" /> */}
                        <img src={profileData?.data?.profileImage?profileData?.data?.profileImage:require("../../assets/images/favicon.png" )} alt="" />
                    </figure>
                    {/* <span><i class="fa fa-pencil" aria-hidden="true"></i>Update Profile</span> */}
                </aside>
                <article>
                    
                    <figcaption>
                        <h3>{profileData?.data?.name}</h3>
                        {/* <p><strong>Email</strong> <span> dipti@gmail</span></p> */}
                        <p><strong>Mobile Number</strong> <span> {profileData?.data?.phone}</span></p>
                        {/* <!-- <p><b>Get Updates on WhatsApp</b></p> --> */}
                        <div class="Actions">
                            <label class="switch">
                                <input type="checkbox"  checked={profileData?.data?.whatsapp_opt_in} />
                                <span class="slider"></span>
                            </label> Get Updates on WhatsApp
                        </div>
                    </figcaption>

                    <Link class="editbtn" to="/edit-profile">Edit your profile</Link>
                </article>
            </div>
        </div>
    </section>
<Footer />

</>  )
}

export default MyProfile