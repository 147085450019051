import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoggedIn } from "../../../utils";

import { url } from "../../../config/config";

export const fetchPglisting = createAsyncThunk(
  "PgListing/fetchPglistin",
  async (payload) => {
    // console.log('This is payload being sent for listing===> ', payload)
    const response = await axios.post(`${url}user/pg/listing`, payload, {
      headers: {
        Authorization: isLoggedIn("pgUser"),
      },
    });
    console.log("PG listing slice data ===> ", response.data);
    return response.data;
  }
);

// const initialState = {
//     pgData: [],
//     status: 'idle',
//     error: null,
// }
const initialState = {
  pgData: [],
  status: "idle",
  error: null,
  currentRequestId: undefined, // track the current request
};

// const pgListingSlice = createSlice({
//     name: 'pgListingData',
//     initialState,
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchPglisting.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(fetchPglisting.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.pgData = action.payload.data?.[0];
//             })
//             .addCase(fetchPglisting.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             });
//     },
// });

const pgListingSlice = createSlice({
  name: "pgListingData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPglisting.pending, (state, action) => {
        state.status = "loading";
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchPglisting.fulfilled, (state, action) => {
        // Only update if this response matches the latest request
        if (state.currentRequestId === action.meta.requestId) {
          state.status = "succeeded";
          state.pgData = action.payload.data?.[0];
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchPglisting.rejected, (state, action) => {
        // Also check for request ID here if needed
        if (state.currentRequestId === action.meta.requestId) {
          state.status = "failed";
          state.error = action.error.message;
          state.currentRequestId = undefined;
        }
      });
  },
});

export default pgListingSlice.reducer;
